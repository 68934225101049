import React, { SyntheticEvent } from 'react';
import { history } from 'App';
import classNames from 'classnames';
import { Button, Checkbox, JustSelect } from '@just-ai/just-ui';
import { AppLogger } from '@just-ai/logger';

import { t } from 'localization';
import { getAvailableCountries, isAxiosError, isDev } from 'pipes/functions';

import { AppContext } from 'components/AppContext';
import AccountsService from 'service/AccountsService';
import LoginService from 'service/LoginService';
import RegisterService from 'service/RegisterService';
import { BasePage, Error } from 'views/BasePage';

import 'react-perfect-scrollbar/dist/css/styles.css';

class CountrySelect extends BasePage<any, any> {
  static contextType = AppContext;
  AccountsService = new AccountsService();
  RegisterService = new RegisterService();
  LoginService = new LoginService();
  state = {
    errors: [],
    countryIsoCode: '',
    isTermsOfUseChecked: false,
    loaded: false,
  };

  componentDidMount() {
    const { appConfig } = this.context;
    if (!appConfig.registration.userNeedToSpecifyCountryIsoCode) {
      history.push('/c/login');
    }
    this.supportAddOnMessageListener();
    this.detectCountryIsoCode();

    const load = async () => {
      const { setCurrentUser, getTosAndPos } = this.context;
      this.setState({ fetching: true });

      try {
        const { data } = await this.LoginService.checkIsUserAuthorized();
        await setCurrentUser(data);
        await getTosAndPos(data.userData.userId);
        this.setState({
          loaded: true,
          fetching: false,
        });
      } catch (e) {
        history.push(`/c/login`);
      }
    };
    load();
  }

  detectCountryIsoCode = async () => {
    try {
      const data = await this.RegisterService.detectCountryIsoCode();
      if (data.data) {
        localStorage.CLOUD_COUNTRY_CODE = data.data;
      }
      this.setState({
        countryIsoCode: data.data,
      });
    } catch (e) {
      if (isAxiosError(e)) {
        AppLogger.error({
          message: 'Error while detecting country iso code',
          exception: e,
        });
      }
    }
  };

  toggleTermsOfUseLink = () => this.setState({ isTermsOfUseChecked: !this.state.isTermsOfUseChecked });

  changeCountry = (country: any) => {
    this.setState({ countryIsoCode: country[0], isCountrySelectOpen: undefined });
  };

  validate = () => {
    const { isTermsOfUseChecked, errors, countryIsoCode } = this.state;

    const commonErrors: Error[] = errors.filter((error: Error) => !error?.args?.path);

    let fieldErrors: { args: { path: string } }[] = [];

    if (!countryIsoCode)
      fieldErrors.push({
        args: {
          path: '$.countryIsoCode',
        },
      });

    if (!isTermsOfUseChecked) {
      fieldErrors.push({
        args: { path: 'termsOfUse' },
      });
    }

    this.setState({ errors: [...commonErrors, ...fieldErrors] });
    return fieldErrors.length === 0;
  };

  submit = async (e: SyntheticEvent) => {
    e.preventDefault();

    if (!this.validate()) return;

    const { currentUser, domainOptions } = this.context;

    const { countryIsoCode } = this.state;

    const countryIsoCodeDto = {
      countryIsoCode: countryIsoCode!,
    };

    this.setState({
      fetching: true,
    });

    try {
      await this.AccountsService.changeCountryIsoCode(currentUser.userData.accountId, countryIsoCodeDto);

      const redirectUrl = localStorage.CLOUD_REDIRECT_URL;

      localStorage.removeItem('CLOUD_REDIRECT_URL');

      this.setState({
        fetching: false,
      });
      if (domainOptions?.showPhoneVerification) {
        history.push(
          `/c/phone-verification?${
            redirectUrl ? 'redirectUrl=' + redirectUrl + '&' : ''
          }countryIsoCode=${countryIsoCode}`
        );
      } else {
        window.location.href = isDev() ? '/' : (redirectUrl as string);
      }
    } catch (error) {
      if (isAxiosError(error)) {
        const errors = error.response?.data.errors || [error.response?.data];
        this.setState({
          errors: errors,
          fetching: false,
        });
      }
    }
  };

  renderHead = () => {
    return (
      <div className='base-page_formarea-head text-left'>
        <h1>{t('Register: form header text')}</h1>
      </div>
    );
  };

  renderInputs = () => {
    const { countryIsoCode, errors, isTermsOfUseChecked } = this.state;
    const { language, appConfig, tosAndPos } = this.context;

    const termsOfUseError: Error | undefined = errors.find((error: Error) => error.args?.path === 'termsOfUse');

    const availableCountriesList = getAvailableCountries(
      language.substr(0, 2).toUpperCase(),
      appConfig.registration.countryIsoCodes
    );

    const isUserCountryAvailable = availableCountriesList.find(
      (option: { value: string; label: string }) => option.value === countryIsoCode
    );

    const selected = availableCountriesList.find(
      (option: { value: string; label: string }) =>
        option.value === (isUserCountryAvailable?.value || appConfig?.registration?.countryIsoCode?.default)
    );
    return (
      <>
        <div className={classNames('form-row', { 'with-error': Boolean(this.renderFieldError('countryIsoCode')) })}>
          <label htmlFor='email'>{t(`Register: field country label`)}</label>
          <JustSelect
            className='countrySelect'
            options={availableCountriesList}
            onChange={this.changeCountry}
            value={selected?.value}
            inputPlaceholder={t('Register: select country placeholder')}
            fullWidth
          />
          {this.renderFieldError('countryIsoCode')}
        </div>
        <div className='form-row'>
          <label>
            <Checkbox
              name='checkbox'
              onChange={this.toggleTermsOfUseLink}
              invalid={Boolean(termsOfUseError)}
              value={isTermsOfUseChecked}
              label={
                <span>
                  {t('Register: I agree with')}
                  <a href={tosAndPos?.termsOfUseUrl} target='_blank' rel='noopener noreferrer'>
                    {t('Register: ToS link text')}
                  </a>{' '}
                  {t('and')}{' '}
                  <a href={tosAndPos?.privacyPolicyUrl} target='_blank' rel='noopener noreferrer'>
                    {t('Register: policy link text')}
                  </a>
                </span>
              }
            />
          </label>
        </div>
      </>
    );
  };

  renderButtons = () => {
    return (
      <div className='base-page_formarea-buttons flex-end'>
        <Button color='primary'>{t('Register: submit button text')}</Button>
      </div>
    );
  };
}

export default CountrySelect;
