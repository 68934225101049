// tslint:disable
// @ts-nocheck
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as globalImportUrl from "url";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, BaseAPI, RequiredError, } from "./base";
/**
 *
 * @export
 * @enum {string}
 */
export var AccountBlockType;
(function (AccountBlockType) {
    AccountBlockType["NONE"] = "NONE";
    AccountBlockType["NOTPAYED"] = "NOT_PAYED";
    AccountBlockType["FULL"] = "FULL";
})(AccountBlockType || (AccountBlockType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var AccountStatus;
(function (AccountStatus) {
    AccountStatus["ACTIVE"] = "ACTIVE";
    AccountStatus["PAYMENTBLOCKED"] = "PAYMENT_BLOCKED";
    AccountStatus["FULLBLOCKED"] = "FULL_BLOCKED";
})(AccountStatus || (AccountStatus = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var AccountVerificationStatus;
(function (AccountVerificationStatus) {
    AccountVerificationStatus["VERIFIED"] = "ACCOUNT_VERIFIED";
    AccountVerificationStatus["ONVERIFICATION"] = "ACCOUNT_ON_VERIFICATION";
    AccountVerificationStatus["NOTVERIFIED"] = "ACCOUNT_NOT_VERIFIED";
})(AccountVerificationStatus || (AccountVerificationStatus = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var BlockType;
(function (BlockType) {
    BlockType["NONE"] = "NONE";
    BlockType["NOTPAYED"] = "NOT_PAYED";
    BlockType["FULL"] = "FULL";
})(BlockType || (BlockType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var ExternalSsoType;
(function (ExternalSsoType) {
    ExternalSsoType["OAUTH2"] = "OAUTH2";
})(ExternalSsoType || (ExternalSsoType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var Locale;
(function (Locale) {
    Locale["RU"] = "RU";
    Locale["EN"] = "EN";
    Locale["CN"] = "CN";
    Locale["PT"] = "PT";
    Locale["KZ"] = "KZ";
    Locale["ES"] = "ES";
})(Locale || (Locale = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var PartnerState;
(function (PartnerState) {
    PartnerState["ACTIVE"] = "ACTIVE";
    PartnerState["BLOCKED"] = "BLOCKED";
    PartnerState["UNVERIFIED"] = "UNVERIFIED";
})(PartnerState || (PartnerState = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var PartnerType;
(function (PartnerType) {
    PartnerType["JAICP"] = "JAICP";
    PartnerType["AIMYLOGIC"] = "AIMYLOGIC";
    PartnerType["JAICPANDAIMYLOGIC"] = "JAICP_AND_AIMYLOGIC";
})(PartnerType || (PartnerType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var PaymentSystem;
(function (PaymentSystem) {
    PaymentSystem["YANDEXCASH"] = "YANDEX_CASH";
    PaymentSystem["UNDEFINED"] = "UNDEFINED";
    PaymentSystem["SYSTEMCASH"] = "SYSTEM_CASH";
    PaymentSystem["STRIPE"] = "STRIPE";
})(PaymentSystem || (PaymentSystem = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var PaymentSystemDto;
(function (PaymentSystemDto) {
    PaymentSystemDto["YOOMONEY"] = "YOOMONEY";
    PaymentSystemDto["STRIPE"] = "STRIPE";
})(PaymentSystemDto || (PaymentSystemDto = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var RoleGroupColor;
(function (RoleGroupColor) {
    RoleGroupColor["Red"] = "red";
    RoleGroupColor["Green"] = "green";
    RoleGroupColor["Blue"] = "blue";
    RoleGroupColor["Gray"] = "gray";
    RoleGroupColor["Yellow"] = "yellow";
    RoleGroupColor["Indigo"] = "indigo";
})(RoleGroupColor || (RoleGroupColor = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var UserMode;
(function (UserMode) {
    UserMode["REGULAR"] = "REGULAR";
    UserMode["INTERNAL"] = "INTERNAL";
})(UserMode || (UserMode = {}));
/**
 * AccountInvitationEndpointApi - axios parameter creator
 * @export
 */
export const AccountInvitationEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvitationByToken(token, options = {}) {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError("token", "Required parameter token was null or undefined when calling acceptInvitationByToken.");
            }
            const localVarPath = `/api/accountsadmin/c/account-invitation/accept-by-token`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (token !== undefined) {
                localVarQueryParameter["token"] = token;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvitationByUserId(userId, accountId, options = {}) {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError("userId", "Required parameter userId was null or undefined when calling acceptInvitationByUserId.");
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling acceptInvitationByUserId.");
            }
            const localVarPath = `/api/accountsadmin/c/account-invitation/accept-by-user-id`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (userId !== undefined) {
                localVarQueryParameter["userId"] = userId;
            }
            if (accountId !== undefined) {
                localVarQueryParameter["accountId"] = accountId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {number} invitationId
         * @param {ChangeRolesDto} changeRolesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeInvitationRoles(accountId, invitationId, changeRolesDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling changeInvitationRoles.");
            }
            // verify required parameter 'invitationId' is not null or undefined
            if (invitationId === null || invitationId === undefined) {
                throw new RequiredError("invitationId", "Required parameter invitationId was null or undefined when calling changeInvitationRoles.");
            }
            // verify required parameter 'changeRolesDto' is not null or undefined
            if (changeRolesDto === null || changeRolesDto === undefined) {
                throw new RequiredError("changeRolesDto", "Required parameter changeRolesDto was null or undefined when calling changeInvitationRoles.");
            }
            const localVarPath = `/api/accountsadmin/c/accounts/{accountId}/invitations/{invitationId}/roles/change`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"invitationId"}}`, encodeURIComponent(String(invitationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof changeRolesDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(changeRolesDto !== undefined ? changeRolesDto : {})
                : changeRolesDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {number} invitationId
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineInvitation(accountId, invitationId, userId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling declineInvitation.");
            }
            // verify required parameter 'invitationId' is not null or undefined
            if (invitationId === null || invitationId === undefined) {
                throw new RequiredError("invitationId", "Required parameter invitationId was null or undefined when calling declineInvitation.");
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError("userId", "Required parameter userId was null or undefined when calling declineInvitation.");
            }
            const localVarPath = `/api/accountsadmin/c/accounts/{accountId}/invitations/{invitationId}/decline`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"invitationId"}}`, encodeURIComponent(String(invitationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (userId !== undefined) {
                localVarQueryParameter["userId"] = userId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {number} invitationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvitation(accountId, invitationId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteInvitation.");
            }
            // verify required parameter 'invitationId' is not null or undefined
            if (invitationId === null || invitationId === undefined) {
                throw new RequiredError("invitationId", "Required parameter invitationId was null or undefined when calling deleteInvitation.");
            }
            const localVarPath = `/api/accountsadmin/c/accounts/{accountId}/invitations/{invitationId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"invitationId"}}`, encodeURIComponent(String(invitationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {number} page
         * @param {number} size
         * @param {string} searchText
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByLoginOrFullNameInAccount(accountId, page, size, searchText, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling findByLoginOrFullNameInAccount.");
            }
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError("page", "Required parameter page was null or undefined when calling findByLoginOrFullNameInAccount.");
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError("size", "Required parameter size was null or undefined when calling findByLoginOrFullNameInAccount.");
            }
            // verify required parameter 'searchText' is not null or undefined
            if (searchText === null || searchText === undefined) {
                throw new RequiredError("searchText", "Required parameter searchText was null or undefined when calling findByLoginOrFullNameInAccount.");
            }
            const localVarPath = `/api/accountsadmin/c/accounts/{accountId}/invitations/search`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (page !== undefined) {
                localVarQueryParameter["page"] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter["size"] = size;
            }
            if (searchText !== undefined) {
                localVarQueryParameter["searchText"] = searchText;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationsByUser(userId, options = {}) {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError("userId", "Required parameter userId was null or undefined when calling getInvitationsByUser.");
            }
            const localVarPath = `/api/accountsadmin/c/account-invitation/get-by-user`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (userId !== undefined) {
                localVarQueryParameter["userId"] = userId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {InviteUsersDto} inviteUsersDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUsers(accountId, inviteUsersDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling inviteUsers.");
            }
            // verify required parameter 'inviteUsersDto' is not null or undefined
            if (inviteUsersDto === null || inviteUsersDto === undefined) {
                throw new RequiredError("inviteUsersDto", "Required parameter inviteUsersDto was null or undefined when calling inviteUsers.");
            }
            const localVarPath = `/api/accountsadmin/c/accounts/{accountId}/bulk-invite`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof inviteUsersDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(inviteUsersDto !== undefined ? inviteUsersDto : {})
                : inviteUsersDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AccountInvitationEndpointApi - functional programming interface
 * @export
 */
export const AccountInvitationEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvitationByToken(token, options) {
            const localVarAxiosArgs = AccountInvitationEndpointApiAxiosParamCreator(configuration).acceptInvitationByToken(token, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvitationByUserId(userId, accountId, options) {
            const localVarAxiosArgs = AccountInvitationEndpointApiAxiosParamCreator(configuration).acceptInvitationByUserId(userId, accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {number} invitationId
         * @param {ChangeRolesDto} changeRolesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeInvitationRoles(accountId, invitationId, changeRolesDto, options) {
            const localVarAxiosArgs = AccountInvitationEndpointApiAxiosParamCreator(configuration).changeInvitationRoles(accountId, invitationId, changeRolesDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {number} invitationId
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineInvitation(accountId, invitationId, userId, options) {
            const localVarAxiosArgs = AccountInvitationEndpointApiAxiosParamCreator(configuration).declineInvitation(accountId, invitationId, userId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {number} invitationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvitation(accountId, invitationId, options) {
            const localVarAxiosArgs = AccountInvitationEndpointApiAxiosParamCreator(configuration).deleteInvitation(accountId, invitationId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {number} page
         * @param {number} size
         * @param {string} searchText
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByLoginOrFullNameInAccount(accountId, page, size, searchText, options) {
            const localVarAxiosArgs = AccountInvitationEndpointApiAxiosParamCreator(configuration).findByLoginOrFullNameInAccount(accountId, page, size, searchText, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationsByUser(userId, options) {
            const localVarAxiosArgs = AccountInvitationEndpointApiAxiosParamCreator(configuration).getInvitationsByUser(userId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {InviteUsersDto} inviteUsersDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUsers(accountId, inviteUsersDto, options) {
            const localVarAxiosArgs = AccountInvitationEndpointApiAxiosParamCreator(configuration).inviteUsers(accountId, inviteUsersDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AccountInvitationEndpointApi - factory interface
 * @export
 */
export const AccountInvitationEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvitationByToken(token, options) {
            return AccountInvitationEndpointApiFp(configuration).acceptInvitationByToken(token, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvitationByUserId(userId, accountId, options) {
            return AccountInvitationEndpointApiFp(configuration).acceptInvitationByUserId(userId, accountId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {number} invitationId
         * @param {ChangeRolesDto} changeRolesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeInvitationRoles(accountId, invitationId, changeRolesDto, options) {
            return AccountInvitationEndpointApiFp(configuration).changeInvitationRoles(accountId, invitationId, changeRolesDto, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {number} invitationId
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declineInvitation(accountId, invitationId, userId, options) {
            return AccountInvitationEndpointApiFp(configuration).declineInvitation(accountId, invitationId, userId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {number} invitationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvitation(accountId, invitationId, options) {
            return AccountInvitationEndpointApiFp(configuration).deleteInvitation(accountId, invitationId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {number} page
         * @param {number} size
         * @param {string} searchText
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByLoginOrFullNameInAccount(accountId, page, size, searchText, options) {
            return AccountInvitationEndpointApiFp(configuration).findByLoginOrFullNameInAccount(accountId, page, size, searchText, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationsByUser(userId, options) {
            return AccountInvitationEndpointApiFp(configuration).getInvitationsByUser(userId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {InviteUsersDto} inviteUsersDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUsers(accountId, inviteUsersDto, options) {
            return AccountInvitationEndpointApiFp(configuration).inviteUsers(accountId, inviteUsersDto, options)(axios, basePath);
        },
    };
};
/**
 * AccountInvitationEndpointApi - object-oriented interface
 * @export
 * @class AccountInvitationEndpointApi
 * @extends {BaseAPI}
 */
export class AccountInvitationEndpointApi extends BaseAPI {
    /**
     *
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountInvitationEndpointApi
     */
    acceptInvitationByToken(token, options) {
        return AccountInvitationEndpointApiFp(this.configuration).acceptInvitationByToken(token, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} userId
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountInvitationEndpointApi
     */
    acceptInvitationByUserId(userId, accountId, options) {
        return AccountInvitationEndpointApiFp(this.configuration).acceptInvitationByUserId(userId, accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId
     * @param {number} invitationId
     * @param {ChangeRolesDto} changeRolesDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountInvitationEndpointApi
     */
    changeInvitationRoles(accountId, invitationId, changeRolesDto, options) {
        return AccountInvitationEndpointApiFp(this.configuration).changeInvitationRoles(accountId, invitationId, changeRolesDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId
     * @param {number} invitationId
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountInvitationEndpointApi
     */
    declineInvitation(accountId, invitationId, userId, options) {
        return AccountInvitationEndpointApiFp(this.configuration).declineInvitation(accountId, invitationId, userId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId
     * @param {number} invitationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountInvitationEndpointApi
     */
    deleteInvitation(accountId, invitationId, options) {
        return AccountInvitationEndpointApiFp(this.configuration).deleteInvitation(accountId, invitationId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId
     * @param {number} page
     * @param {number} size
     * @param {string} searchText
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountInvitationEndpointApi
     */
    findByLoginOrFullNameInAccount(accountId, page, size, searchText, options) {
        return AccountInvitationEndpointApiFp(this.configuration).findByLoginOrFullNameInAccount(accountId, page, size, searchText, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountInvitationEndpointApi
     */
    getInvitationsByUser(userId, options) {
        return AccountInvitationEndpointApiFp(this.configuration).getInvitationsByUser(userId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId
     * @param {InviteUsersDto} inviteUsersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountInvitationEndpointApi
     */
    inviteUsers(accountId, inviteUsersDto, options) {
        return AccountInvitationEndpointApiFp(this.configuration).inviteUsers(accountId, inviteUsersDto, options)(this.axios, this.basePath);
    }
}
/**
 * AccountManagementEndpointApi - axios parameter creator
 * @export
 */
export const AccountManagementEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {AccountAddUsersRequestDto} accountAddUsersRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUsersToAccount(accountId, accountAddUsersRequestDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling addUsersToAccount.");
            }
            // verify required parameter 'accountAddUsersRequestDto' is not null or undefined
            if (accountAddUsersRequestDto === null ||
                accountAddUsersRequestDto === undefined) {
                throw new RequiredError("accountAddUsersRequestDto", "Required parameter accountAddUsersRequestDto was null or undefined when calling addUsersToAccount.");
            }
            const localVarPath = `/api/accountsadmin/c/sadmin/accounts/{accountId}/add-users`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof accountAddUsersRequestDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(accountAddUsersRequestDto !== undefined
                    ? accountAddUsersRequestDto
                    : {})
                : accountAddUsersRequestDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {AccountsBulkEditAllRequestDto} accountsBulkEditAllRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditAllAccountsAsSadmin(accountsBulkEditAllRequestDto, options = {}) {
            // verify required parameter 'accountsBulkEditAllRequestDto' is not null or undefined
            if (accountsBulkEditAllRequestDto === null ||
                accountsBulkEditAllRequestDto === undefined) {
                throw new RequiredError("accountsBulkEditAllRequestDto", "Required parameter accountsBulkEditAllRequestDto was null or undefined when calling bulkEditAllAccountsAsSadmin.");
            }
            const localVarPath = `/api/accountsadmin/c/sadmin/accounts/bulk-edit/all`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof accountsBulkEditAllRequestDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(accountsBulkEditAllRequestDto !== undefined
                    ? accountsBulkEditAllRequestDto
                    : {})
                : accountsBulkEditAllRequestDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {AccountsBulkEditSelectedRequestDto} accountsBulkEditSelectedRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditSelectedAccountsAsSadmin(accountsBulkEditSelectedRequestDto, options = {}) {
            // verify required parameter 'accountsBulkEditSelectedRequestDto' is not null or undefined
            if (accountsBulkEditSelectedRequestDto === null ||
                accountsBulkEditSelectedRequestDto === undefined) {
                throw new RequiredError("accountsBulkEditSelectedRequestDto", "Required parameter accountsBulkEditSelectedRequestDto was null or undefined when calling bulkEditSelectedAccountsAsSadmin.");
            }
            const localVarPath = `/api/accountsadmin/c/sadmin/accounts/bulk-edit/selected`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof accountsBulkEditSelectedRequestDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(accountsBulkEditSelectedRequestDto !== undefined
                    ? accountsBulkEditSelectedRequestDto
                    : {})
                : accountsBulkEditSelectedRequestDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {CreateAccountAsSadminDto} createAccountAsSadminDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountAsSadmin(createAccountAsSadminDto, options = {}) {
            // verify required parameter 'createAccountAsSadminDto' is not null or undefined
            if (createAccountAsSadminDto === null ||
                createAccountAsSadminDto === undefined) {
                throw new RequiredError("createAccountAsSadminDto", "Required parameter createAccountAsSadminDto was null or undefined when calling createAccountAsSadmin.");
            }
            const localVarPath = `/api/accountsadmin/c/sadmin/accounts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof createAccountAsSadminDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createAccountAsSadminDto !== undefined
                    ? createAccountAsSadminDto
                    : {})
                : createAccountAsSadminDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {AccountSearchFilter} accountSearchFilter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAccountsByFilterAsSadmin(accountSearchFilter, options = {}) {
            // verify required parameter 'accountSearchFilter' is not null or undefined
            if (accountSearchFilter === null || accountSearchFilter === undefined) {
                throw new RequiredError("accountSearchFilter", "Required parameter accountSearchFilter was null or undefined when calling findAccountsByFilterAsSadmin.");
            }
            const localVarPath = `/api/accountsadmin/c/sadmin/accounts/search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof accountSearchFilter !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(accountSearchFilter !== undefined ? accountSearchFilter : {})
                : accountSearchFilter || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountAsSadmin(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getAccountAsSadmin.");
            }
            const localVarPath = `/api/accountsadmin/c/sadmin/accounts/{accountId}`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffsForSearch(options = {}) {
            const localVarPath = `/api/accountsadmin/c/sadmin/accounts/get-tariffs-for-search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {UpdateAccountAsSadminDto} updateAccountAsSadminDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountAsSadmin(accountId, updateAccountAsSadminDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateAccountAsSadmin.");
            }
            // verify required parameter 'updateAccountAsSadminDto' is not null or undefined
            if (updateAccountAsSadminDto === null ||
                updateAccountAsSadminDto === undefined) {
                throw new RequiredError("updateAccountAsSadminDto", "Required parameter updateAccountAsSadminDto was null or undefined when calling updateAccountAsSadmin.");
            }
            const localVarPath = `/api/accountsadmin/c/sadmin/accounts/{accountId}`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof updateAccountAsSadminDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updateAccountAsSadminDto !== undefined
                    ? updateAccountAsSadminDto
                    : {})
                : updateAccountAsSadminDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AccountManagementEndpointApi - functional programming interface
 * @export
 */
export const AccountManagementEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {AccountAddUsersRequestDto} accountAddUsersRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUsersToAccount(accountId, accountAddUsersRequestDto, options) {
            const localVarAxiosArgs = AccountManagementEndpointApiAxiosParamCreator(configuration).addUsersToAccount(accountId, accountAddUsersRequestDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {AccountsBulkEditAllRequestDto} accountsBulkEditAllRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditAllAccountsAsSadmin(accountsBulkEditAllRequestDto, options) {
            const localVarAxiosArgs = AccountManagementEndpointApiAxiosParamCreator(configuration).bulkEditAllAccountsAsSadmin(accountsBulkEditAllRequestDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {AccountsBulkEditSelectedRequestDto} accountsBulkEditSelectedRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditSelectedAccountsAsSadmin(accountsBulkEditSelectedRequestDto, options) {
            const localVarAxiosArgs = AccountManagementEndpointApiAxiosParamCreator(configuration).bulkEditSelectedAccountsAsSadmin(accountsBulkEditSelectedRequestDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {CreateAccountAsSadminDto} createAccountAsSadminDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountAsSadmin(createAccountAsSadminDto, options) {
            const localVarAxiosArgs = AccountManagementEndpointApiAxiosParamCreator(configuration).createAccountAsSadmin(createAccountAsSadminDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {AccountSearchFilter} accountSearchFilter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAccountsByFilterAsSadmin(accountSearchFilter, options) {
            const localVarAxiosArgs = AccountManagementEndpointApiAxiosParamCreator(configuration).findAccountsByFilterAsSadmin(accountSearchFilter, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountAsSadmin(accountId, options) {
            const localVarAxiosArgs = AccountManagementEndpointApiAxiosParamCreator(configuration).getAccountAsSadmin(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffsForSearch(options) {
            const localVarAxiosArgs = AccountManagementEndpointApiAxiosParamCreator(configuration).getTariffsForSearch(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {UpdateAccountAsSadminDto} updateAccountAsSadminDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountAsSadmin(accountId, updateAccountAsSadminDto, options) {
            const localVarAxiosArgs = AccountManagementEndpointApiAxiosParamCreator(configuration).updateAccountAsSadmin(accountId, updateAccountAsSadminDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AccountManagementEndpointApi - factory interface
 * @export
 */
export const AccountManagementEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {AccountAddUsersRequestDto} accountAddUsersRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUsersToAccount(accountId, accountAddUsersRequestDto, options) {
            return AccountManagementEndpointApiFp(configuration).addUsersToAccount(accountId, accountAddUsersRequestDto, options)(axios, basePath);
        },
        /**
         *
         * @param {AccountsBulkEditAllRequestDto} accountsBulkEditAllRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditAllAccountsAsSadmin(accountsBulkEditAllRequestDto, options) {
            return AccountManagementEndpointApiFp(configuration).bulkEditAllAccountsAsSadmin(accountsBulkEditAllRequestDto, options)(axios, basePath);
        },
        /**
         *
         * @param {AccountsBulkEditSelectedRequestDto} accountsBulkEditSelectedRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkEditSelectedAccountsAsSadmin(accountsBulkEditSelectedRequestDto, options) {
            return AccountManagementEndpointApiFp(configuration).bulkEditSelectedAccountsAsSadmin(accountsBulkEditSelectedRequestDto, options)(axios, basePath);
        },
        /**
         *
         * @param {CreateAccountAsSadminDto} createAccountAsSadminDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountAsSadmin(createAccountAsSadminDto, options) {
            return AccountManagementEndpointApiFp(configuration).createAccountAsSadmin(createAccountAsSadminDto, options)(axios, basePath);
        },
        /**
         *
         * @param {AccountSearchFilter} accountSearchFilter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAccountsByFilterAsSadmin(accountSearchFilter, options) {
            return AccountManagementEndpointApiFp(configuration).findAccountsByFilterAsSadmin(accountSearchFilter, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountAsSadmin(accountId, options) {
            return AccountManagementEndpointApiFp(configuration).getAccountAsSadmin(accountId, options)(axios, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffsForSearch(options) {
            return AccountManagementEndpointApiFp(configuration).getTariffsForSearch(options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {UpdateAccountAsSadminDto} updateAccountAsSadminDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountAsSadmin(accountId, updateAccountAsSadminDto, options) {
            return AccountManagementEndpointApiFp(configuration).updateAccountAsSadmin(accountId, updateAccountAsSadminDto, options)(axios, basePath);
        },
    };
};
/**
 * AccountManagementEndpointApi - object-oriented interface
 * @export
 * @class AccountManagementEndpointApi
 * @extends {BaseAPI}
 */
export class AccountManagementEndpointApi extends BaseAPI {
    /**
     *
     * @param {number} accountId
     * @param {AccountAddUsersRequestDto} accountAddUsersRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementEndpointApi
     */
    addUsersToAccount(accountId, accountAddUsersRequestDto, options) {
        return AccountManagementEndpointApiFp(this.configuration).addUsersToAccount(accountId, accountAddUsersRequestDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {AccountsBulkEditAllRequestDto} accountsBulkEditAllRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementEndpointApi
     */
    bulkEditAllAccountsAsSadmin(accountsBulkEditAllRequestDto, options) {
        return AccountManagementEndpointApiFp(this.configuration).bulkEditAllAccountsAsSadmin(accountsBulkEditAllRequestDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {AccountsBulkEditSelectedRequestDto} accountsBulkEditSelectedRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementEndpointApi
     */
    bulkEditSelectedAccountsAsSadmin(accountsBulkEditSelectedRequestDto, options) {
        return AccountManagementEndpointApiFp(this.configuration).bulkEditSelectedAccountsAsSadmin(accountsBulkEditSelectedRequestDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {CreateAccountAsSadminDto} createAccountAsSadminDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementEndpointApi
     */
    createAccountAsSadmin(createAccountAsSadminDto, options) {
        return AccountManagementEndpointApiFp(this.configuration).createAccountAsSadmin(createAccountAsSadminDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {AccountSearchFilter} accountSearchFilter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementEndpointApi
     */
    findAccountsByFilterAsSadmin(accountSearchFilter, options) {
        return AccountManagementEndpointApiFp(this.configuration).findAccountsByFilterAsSadmin(accountSearchFilter, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementEndpointApi
     */
    getAccountAsSadmin(accountId, options) {
        return AccountManagementEndpointApiFp(this.configuration).getAccountAsSadmin(accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementEndpointApi
     */
    getTariffsForSearch(options) {
        return AccountManagementEndpointApiFp(this.configuration).getTariffsForSearch(options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId
     * @param {UpdateAccountAsSadminDto} updateAccountAsSadminDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountManagementEndpointApi
     */
    updateAccountAsSadmin(accountId, updateAccountAsSadminDto, options) {
        return AccountManagementEndpointApiFp(this.configuration).updateAccountAsSadmin(accountId, updateAccountAsSadminDto, options)(this.axios, this.basePath);
    }
}
/**
 * AccountPaymentEndpointApi - axios parameter creator
 * @export
 */
export const AccountPaymentEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {CreatePaymentSubscriptionDto} createPaymentSubscriptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPaymentSubscription(accountId, createPaymentSubscriptionDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createPaymentSubscription.");
            }
            // verify required parameter 'createPaymentSubscriptionDto' is not null or undefined
            if (createPaymentSubscriptionDto === null ||
                createPaymentSubscriptionDto === undefined) {
                throw new RequiredError("createPaymentSubscriptionDto", "Required parameter createPaymentSubscriptionDto was null or undefined when calling createPaymentSubscription.");
            }
            const localVarPath = `/api/accountsadmin/c/billing/accounts/{accountId}/payments/subscription`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof createPaymentSubscriptionDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createPaymentSubscriptionDto !== undefined
                    ? createPaymentSubscriptionDto
                    : {})
                : createPaymentSubscriptionDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePaymentSubscription(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deletePaymentSubscription.");
            }
            const localVarPath = `/api/accountsadmin/c/billing/accounts/{accountId}/payments/subscription`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentStatus(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getPaymentStatus.");
            }
            const localVarPath = `/api/accountsadmin/c/billing/accounts/{accountId}/payments`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {string} paymentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentStatusById(accountId, paymentId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getPaymentStatusById.");
            }
            // verify required parameter 'paymentId' is not null or undefined
            if (paymentId === null || paymentId === undefined) {
                throw new RequiredError("paymentId", "Required parameter paymentId was null or undefined when calling getPaymentStatusById.");
            }
            const localVarPath = `/api/accountsadmin/c/billing/accounts/{accountId}/payments/{paymentId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentSubscription(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getPaymentSubscription.");
            }
            const localVarPath = `/api/accountsadmin/c/billing/accounts/{accountId}/payments/subscription`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {UpdatePaymentSubscriptionDto} updatePaymentSubscriptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentSubscription(accountId, updatePaymentSubscriptionDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updatePaymentSubscription.");
            }
            // verify required parameter 'updatePaymentSubscriptionDto' is not null or undefined
            if (updatePaymentSubscriptionDto === null ||
                updatePaymentSubscriptionDto === undefined) {
                throw new RequiredError("updatePaymentSubscriptionDto", "Required parameter updatePaymentSubscriptionDto was null or undefined when calling updatePaymentSubscription.");
            }
            const localVarPath = `/api/accountsadmin/c/billing/accounts/{accountId}/payments/subscription`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof updatePaymentSubscriptionDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updatePaymentSubscriptionDto !== undefined
                    ? updatePaymentSubscriptionDto
                    : {})
                : updatePaymentSubscriptionDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AccountPaymentEndpointApi - functional programming interface
 * @export
 */
export const AccountPaymentEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {CreatePaymentSubscriptionDto} createPaymentSubscriptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPaymentSubscription(accountId, createPaymentSubscriptionDto, options) {
            const localVarAxiosArgs = AccountPaymentEndpointApiAxiosParamCreator(configuration).createPaymentSubscription(accountId, createPaymentSubscriptionDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePaymentSubscription(accountId, options) {
            const localVarAxiosArgs = AccountPaymentEndpointApiAxiosParamCreator(configuration).deletePaymentSubscription(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentStatus(accountId, options) {
            const localVarAxiosArgs = AccountPaymentEndpointApiAxiosParamCreator(configuration).getPaymentStatus(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {string} paymentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentStatusById(accountId, paymentId, options) {
            const localVarAxiosArgs = AccountPaymentEndpointApiAxiosParamCreator(configuration).getPaymentStatusById(accountId, paymentId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentSubscription(accountId, options) {
            const localVarAxiosArgs = AccountPaymentEndpointApiAxiosParamCreator(configuration).getPaymentSubscription(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {UpdatePaymentSubscriptionDto} updatePaymentSubscriptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentSubscription(accountId, updatePaymentSubscriptionDto, options) {
            const localVarAxiosArgs = AccountPaymentEndpointApiAxiosParamCreator(configuration).updatePaymentSubscription(accountId, updatePaymentSubscriptionDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AccountPaymentEndpointApi - factory interface
 * @export
 */
export const AccountPaymentEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {CreatePaymentSubscriptionDto} createPaymentSubscriptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPaymentSubscription(accountId, createPaymentSubscriptionDto, options) {
            return AccountPaymentEndpointApiFp(configuration).createPaymentSubscription(accountId, createPaymentSubscriptionDto, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePaymentSubscription(accountId, options) {
            return AccountPaymentEndpointApiFp(configuration).deletePaymentSubscription(accountId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentStatus(accountId, options) {
            return AccountPaymentEndpointApiFp(configuration).getPaymentStatus(accountId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {string} paymentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentStatusById(accountId, paymentId, options) {
            return AccountPaymentEndpointApiFp(configuration).getPaymentStatusById(accountId, paymentId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentSubscription(accountId, options) {
            return AccountPaymentEndpointApiFp(configuration).getPaymentSubscription(accountId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {UpdatePaymentSubscriptionDto} updatePaymentSubscriptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentSubscription(accountId, updatePaymentSubscriptionDto, options) {
            return AccountPaymentEndpointApiFp(configuration).updatePaymentSubscription(accountId, updatePaymentSubscriptionDto, options)(axios, basePath);
        },
    };
};
/**
 * AccountPaymentEndpointApi - object-oriented interface
 * @export
 * @class AccountPaymentEndpointApi
 * @extends {BaseAPI}
 */
export class AccountPaymentEndpointApi extends BaseAPI {
    /**
     *
     * @param {number} accountId
     * @param {CreatePaymentSubscriptionDto} createPaymentSubscriptionDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountPaymentEndpointApi
     */
    createPaymentSubscription(accountId, createPaymentSubscriptionDto, options) {
        return AccountPaymentEndpointApiFp(this.configuration).createPaymentSubscription(accountId, createPaymentSubscriptionDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountPaymentEndpointApi
     */
    deletePaymentSubscription(accountId, options) {
        return AccountPaymentEndpointApiFp(this.configuration).deletePaymentSubscription(accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountPaymentEndpointApi
     */
    getPaymentStatus(accountId, options) {
        return AccountPaymentEndpointApiFp(this.configuration).getPaymentStatus(accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId
     * @param {string} paymentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountPaymentEndpointApi
     */
    getPaymentStatusById(accountId, paymentId, options) {
        return AccountPaymentEndpointApiFp(this.configuration).getPaymentStatusById(accountId, paymentId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountPaymentEndpointApi
     */
    getPaymentSubscription(accountId, options) {
        return AccountPaymentEndpointApiFp(this.configuration).getPaymentSubscription(accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId
     * @param {UpdatePaymentSubscriptionDto} updatePaymentSubscriptionDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountPaymentEndpointApi
     */
    updatePaymentSubscription(accountId, updatePaymentSubscriptionDto, options) {
        return AccountPaymentEndpointApiFp(this.configuration).updatePaymentSubscription(accountId, updatePaymentSubscriptionDto, options)(this.axios, this.basePath);
    }
}
/**
 * AccountUserEndpointApi - axios parameter creator
 * @export
 */
export const AccountUserEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {number} userId
         * @param {ChangeRolesDto} changeRolesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountRoles(accountId, userId, changeRolesDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling changeAccountRoles.");
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError("userId", "Required parameter userId was null or undefined when calling changeAccountRoles.");
            }
            // verify required parameter 'changeRolesDto' is not null or undefined
            if (changeRolesDto === null || changeRolesDto === undefined) {
                throw new RequiredError("changeRolesDto", "Required parameter changeRolesDto was null or undefined when calling changeAccountRoles.");
            }
            const localVarPath = `/api/accountsadmin/c/accounts/{accountId}/users/{userId}/roles/change`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof changeRolesDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(changeRolesDto !== undefined ? changeRolesDto : {})
                : changeRolesDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserFromAccount(accountId, userId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteUserFromAccount.");
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError("userId", "Required parameter userId was null or undefined when calling deleteUserFromAccount.");
            }
            const localVarPath = `/api/accountsadmin/c/accounts/{accountId}/users/{userId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AccountUserEndpointApi - functional programming interface
 * @export
 */
export const AccountUserEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {number} userId
         * @param {ChangeRolesDto} changeRolesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountRoles(accountId, userId, changeRolesDto, options) {
            const localVarAxiosArgs = AccountUserEndpointApiAxiosParamCreator(configuration).changeAccountRoles(accountId, userId, changeRolesDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserFromAccount(accountId, userId, options) {
            const localVarAxiosArgs = AccountUserEndpointApiAxiosParamCreator(configuration).deleteUserFromAccount(accountId, userId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AccountUserEndpointApi - factory interface
 * @export
 */
export const AccountUserEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {number} userId
         * @param {ChangeRolesDto} changeRolesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountRoles(accountId, userId, changeRolesDto, options) {
            return AccountUserEndpointApiFp(configuration).changeAccountRoles(accountId, userId, changeRolesDto, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserFromAccount(accountId, userId, options) {
            return AccountUserEndpointApiFp(configuration).deleteUserFromAccount(accountId, userId, options)(axios, basePath);
        },
    };
};
/**
 * AccountUserEndpointApi - object-oriented interface
 * @export
 * @class AccountUserEndpointApi
 * @extends {BaseAPI}
 */
export class AccountUserEndpointApi extends BaseAPI {
    /**
     *
     * @param {number} accountId
     * @param {number} userId
     * @param {ChangeRolesDto} changeRolesDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUserEndpointApi
     */
    changeAccountRoles(accountId, userId, changeRolesDto, options) {
        return AccountUserEndpointApiFp(this.configuration).changeAccountRoles(accountId, userId, changeRolesDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUserEndpointApi
     */
    deleteUserFromAccount(accountId, userId, options) {
        return AccountUserEndpointApiFp(this.configuration).deleteUserFromAccount(accountId, userId, options)(this.axios, this.basePath);
    }
}
/**
 * AccountVerificationEndpointApi - axios parameter creator
 * @export
 */
export const AccountVerificationEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {AccountVerificationDto} accountVerificationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificationRequest(accountId, accountVerificationDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling verificationRequest.");
            }
            // verify required parameter 'accountVerificationDto' is not null or undefined
            if (accountVerificationDto === null ||
                accountVerificationDto === undefined) {
                throw new RequiredError("accountVerificationDto", "Required parameter accountVerificationDto was null or undefined when calling verificationRequest.");
            }
            const localVarPath = `/api/accountsadmin/c/verification/accounts/{accountId}/verification-request`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof accountVerificationDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(accountVerificationDto !== undefined ? accountVerificationDto : {})
                : accountVerificationDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AccountVerificationEndpointApi - functional programming interface
 * @export
 */
export const AccountVerificationEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {AccountVerificationDto} accountVerificationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificationRequest(accountId, accountVerificationDto, options) {
            const localVarAxiosArgs = AccountVerificationEndpointApiAxiosParamCreator(configuration).verificationRequest(accountId, accountVerificationDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AccountVerificationEndpointApi - factory interface
 * @export
 */
export const AccountVerificationEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {AccountVerificationDto} accountVerificationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificationRequest(accountId, accountVerificationDto, options) {
            return AccountVerificationEndpointApiFp(configuration).verificationRequest(accountId, accountVerificationDto, options)(axios, basePath);
        },
    };
};
/**
 * AccountVerificationEndpointApi - object-oriented interface
 * @export
 * @class AccountVerificationEndpointApi
 * @extends {BaseAPI}
 */
export class AccountVerificationEndpointApi extends BaseAPI {
    /**
     *
     * @param {number} accountId
     * @param {AccountVerificationDto} accountVerificationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountVerificationEndpointApi
     */
    verificationRequest(accountId, accountVerificationDto, options) {
        return AccountVerificationEndpointApiFp(this.configuration).verificationRequest(accountId, accountVerificationDto, options)(this.axios, this.basePath);
    }
}
/**
 * AccountsEndpointV2Api - axios parameter creator
 * @export
 */
export const AccountsEndpointV2ApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {ChangeCountryIsoCodeRequest} changeCountryIsoCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeCountryIsoCode(accountId, changeCountryIsoCodeRequest, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling changeCountryIsoCode.");
            }
            // verify required parameter 'changeCountryIsoCodeRequest' is not null or undefined
            if (changeCountryIsoCodeRequest === null ||
                changeCountryIsoCodeRequest === undefined) {
                throw new RequiredError("changeCountryIsoCodeRequest", "Required parameter changeCountryIsoCodeRequest was null or undefined when calling changeCountryIsoCode.");
            }
            const localVarPath = `/api/accountsadmin/c/accounts/{accountId}/change-country-iso-code`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof changeCountryIsoCodeRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(changeCountryIsoCodeRequest !== undefined
                    ? changeCountryIsoCodeRequest
                    : {})
                : changeCountryIsoCodeRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {AccountFullNameDto} accountFullNameDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(accountFullNameDto, options = {}) {
            // verify required parameter 'accountFullNameDto' is not null or undefined
            if (accountFullNameDto === null || accountFullNameDto === undefined) {
                throw new RequiredError("accountFullNameDto", "Required parameter accountFullNameDto was null or undefined when calling createAccount.");
            }
            const localVarPath = `/api/accountsadmin/c/accounts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof accountFullNameDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(accountFullNameDto !== undefined ? accountFullNameDto : {})
                : accountFullNameDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getAccount.");
            }
            const localVarPath = `/api/accountsadmin/c/accounts/{accountId}`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountCapacity(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getAccountCapacity.");
            }
            const localVarPath = `/api/accountsadmin/c/accounts/{accountId}/capacity`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {UpdateAccountAsOwnerDto} updateAccountAsOwnerDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(accountId, updateAccountAsOwnerDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateAccount.");
            }
            // verify required parameter 'updateAccountAsOwnerDto' is not null or undefined
            if (updateAccountAsOwnerDto === null ||
                updateAccountAsOwnerDto === undefined) {
                throw new RequiredError("updateAccountAsOwnerDto", "Required parameter updateAccountAsOwnerDto was null or undefined when calling updateAccount.");
            }
            const localVarPath = `/api/accountsadmin/c/accounts/{accountId}`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof updateAccountAsOwnerDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updateAccountAsOwnerDto !== undefined
                    ? updateAccountAsOwnerDto
                    : {})
                : updateAccountAsOwnerDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AccountsEndpointV2Api - functional programming interface
 * @export
 */
export const AccountsEndpointV2ApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {ChangeCountryIsoCodeRequest} changeCountryIsoCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeCountryIsoCode(accountId, changeCountryIsoCodeRequest, options) {
            const localVarAxiosArgs = AccountsEndpointV2ApiAxiosParamCreator(configuration).changeCountryIsoCode(accountId, changeCountryIsoCodeRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {AccountFullNameDto} accountFullNameDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(accountFullNameDto, options) {
            const localVarAxiosArgs = AccountsEndpointV2ApiAxiosParamCreator(configuration).createAccount(accountFullNameDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(accountId, options) {
            const localVarAxiosArgs = AccountsEndpointV2ApiAxiosParamCreator(configuration).getAccount(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountCapacity(accountId, options) {
            const localVarAxiosArgs = AccountsEndpointV2ApiAxiosParamCreator(configuration).getAccountCapacity(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {UpdateAccountAsOwnerDto} updateAccountAsOwnerDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(accountId, updateAccountAsOwnerDto, options) {
            const localVarAxiosArgs = AccountsEndpointV2ApiAxiosParamCreator(configuration).updateAccount(accountId, updateAccountAsOwnerDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AccountsEndpointV2Api - factory interface
 * @export
 */
export const AccountsEndpointV2ApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {ChangeCountryIsoCodeRequest} changeCountryIsoCodeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeCountryIsoCode(accountId, changeCountryIsoCodeRequest, options) {
            return AccountsEndpointV2ApiFp(configuration).changeCountryIsoCode(accountId, changeCountryIsoCodeRequest, options)(axios, basePath);
        },
        /**
         *
         * @param {AccountFullNameDto} accountFullNameDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(accountFullNameDto, options) {
            return AccountsEndpointV2ApiFp(configuration).createAccount(accountFullNameDto, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(accountId, options) {
            return AccountsEndpointV2ApiFp(configuration).getAccount(accountId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountCapacity(accountId, options) {
            return AccountsEndpointV2ApiFp(configuration).getAccountCapacity(accountId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {UpdateAccountAsOwnerDto} updateAccountAsOwnerDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(accountId, updateAccountAsOwnerDto, options) {
            return AccountsEndpointV2ApiFp(configuration).updateAccount(accountId, updateAccountAsOwnerDto, options)(axios, basePath);
        },
    };
};
/**
 * AccountsEndpointV2Api - object-oriented interface
 * @export
 * @class AccountsEndpointV2Api
 * @extends {BaseAPI}
 */
export class AccountsEndpointV2Api extends BaseAPI {
    /**
     *
     * @param {number} accountId
     * @param {ChangeCountryIsoCodeRequest} changeCountryIsoCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsEndpointV2Api
     */
    changeCountryIsoCode(accountId, changeCountryIsoCodeRequest, options) {
        return AccountsEndpointV2ApiFp(this.configuration).changeCountryIsoCode(accountId, changeCountryIsoCodeRequest, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {AccountFullNameDto} accountFullNameDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsEndpointV2Api
     */
    createAccount(accountFullNameDto, options) {
        return AccountsEndpointV2ApiFp(this.configuration).createAccount(accountFullNameDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsEndpointV2Api
     */
    getAccount(accountId, options) {
        return AccountsEndpointV2ApiFp(this.configuration).getAccount(accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsEndpointV2Api
     */
    getAccountCapacity(accountId, options) {
        return AccountsEndpointV2ApiFp(this.configuration).getAccountCapacity(accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId
     * @param {UpdateAccountAsOwnerDto} updateAccountAsOwnerDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsEndpointV2Api
     */
    updateAccount(accountId, updateAccountAsOwnerDto, options) {
        return AccountsEndpointV2ApiFp(this.configuration).updateAccount(accountId, updateAccountAsOwnerDto, options)(this.axios, this.basePath);
    }
}
/**
 * AuthorizationEndpointApi - axios parameter creator
 * @export
 */
export const AuthorizationEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {TokenAndRedirectUrlDto} params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authAndRedirect(params, options = {}) {
            // verify required parameter 'params' is not null or undefined
            if (params === null || params === undefined) {
                throw new RequiredError("params", "Required parameter params was null or undefined when calling authAndRedirect.");
            }
            const localVarPath = `/api/accountsadmin/public/authorization/auth-and-redirect`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (params !== undefined) {
                localVarQueryParameter["params"] = params;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {AutoLoginDto} autoLoginDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoLogin(autoLoginDto, options = {}) {
            // verify required parameter 'autoLoginDto' is not null or undefined
            if (autoLoginDto === null || autoLoginDto === undefined) {
                throw new RequiredError("autoLoginDto", "Required parameter autoLoginDto was null or undefined when calling autoLogin.");
            }
            const localVarPath = `/api/accountsadmin/public/authorization/auto-login`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof autoLoginDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(autoLoginDto !== undefined ? autoLoginDto : {})
                : autoLoginDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {ChangeAccountDto} changeAccountDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccount(changeAccountDto, options = {}) {
            // verify required parameter 'changeAccountDto' is not null or undefined
            if (changeAccountDto === null || changeAccountDto === undefined) {
                throw new RequiredError("changeAccountDto", "Required parameter changeAccountDto was null or undefined when calling changeAccount.");
            }
            const localVarPath = `/api/accountsadmin/c/authorization/change-account`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof changeAccountDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(changeAccountDto !== undefined ? changeAccountDto : {})
                : changeAccountDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [forDomain]
         * @param {string} [redirectUrl]
         * @param {string} [loginPath]
         * @param {boolean} [omitRedirectUrlWhenNeedAuth]
         * @param {number} [accountId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountAndRedirect(forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, accountId, options = {}) {
            const localVarPath = `/api/accountsadmin/c/authorization/change-account-and-redirect`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (forDomain !== undefined) {
                localVarQueryParameter["forDomain"] = forDomain;
            }
            if (redirectUrl !== undefined) {
                localVarQueryParameter["redirectUrl"] = redirectUrl;
            }
            if (loginPath !== undefined) {
                localVarQueryParameter["loginPath"] = loginPath;
            }
            if (omitRedirectUrlWhenNeedAuth !== undefined) {
                localVarQueryParameter["omitRedirectUrlWhenNeedAuth"] =
                    omitRedirectUrlWhenNeedAuth;
            }
            if (accountId !== undefined) {
                localVarQueryParameter["accountId"] = accountId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UserMode} userMode
         * @param {string} [forDomain]
         * @param {string} [redirectUrl]
         * @param {string} [loginPath]
         * @param {boolean} [omitRedirectUrlWhenNeedAuth]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeModeAndRedirect(userMode, forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, options = {}) {
            // verify required parameter 'userMode' is not null or undefined
            if (userMode === null || userMode === undefined) {
                throw new RequiredError("userMode", "Required parameter userMode was null or undefined when calling changeModeAndRedirect.");
            }
            const localVarPath = `/api/accountsadmin/c/authorization/change-user-mode-and-redirect`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (forDomain !== undefined) {
                localVarQueryParameter["forDomain"] = forDomain;
            }
            if (redirectUrl !== undefined) {
                localVarQueryParameter["redirectUrl"] = redirectUrl;
            }
            if (loginPath !== undefined) {
                localVarQueryParameter["loginPath"] = loginPath;
            }
            if (omitRedirectUrlWhenNeedAuth !== undefined) {
                localVarQueryParameter["omitRedirectUrlWhenNeedAuth"] =
                    omitRedirectUrlWhenNeedAuth;
            }
            if (userMode !== undefined) {
                localVarQueryParameter["userMode"] = userMode;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkIsUserAuthorized(options = {}) {
            const localVarPath = `/api/accountsadmin/c/authorization/check-authorized`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {string} [forDomain]
         * @param {string} [redirectUrl]
         * @param {string} [loginPath]
         * @param {boolean} [omitRedirectUrlWhenNeedAuth]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        impersonateUserAndRedirect(userId, forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, options = {}) {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError("userId", "Required parameter userId was null or undefined when calling impersonateUserAndRedirect.");
            }
            const localVarPath = `/api/accountsadmin/c/authorization/impersonate-user-and-redirect`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (forDomain !== undefined) {
                localVarQueryParameter["forDomain"] = forDomain;
            }
            if (redirectUrl !== undefined) {
                localVarQueryParameter["redirectUrl"] = redirectUrl;
            }
            if (loginPath !== undefined) {
                localVarQueryParameter["loginPath"] = loginPath;
            }
            if (omitRedirectUrlWhenNeedAuth !== undefined) {
                localVarQueryParameter["omitRedirectUrlWhenNeedAuth"] =
                    omitRedirectUrlWhenNeedAuth;
            }
            if (userId !== undefined) {
                localVarQueryParameter["userId"] = userId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {LoginDto} loginDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginDto, options = {}) {
            // verify required parameter 'loginDto' is not null or undefined
            if (loginDto === null || loginDto === undefined) {
                throw new RequiredError("loginDto", "Required parameter loginDto was null or undefined when calling login.");
            }
            const localVarPath = `/api/accountsadmin/public/authorization/login`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof loginDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(loginDto !== undefined ? loginDto : {})
                : loginDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout1(options = {}) {
            const localVarPath = `/api/accountsadmin/c/authorization/logout`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [forDomain]
         * @param {string} [redirectUrl]
         * @param {string} [loginPath]
         * @param {boolean} [omitRedirectUrlWhenNeedAuth]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performSSO(forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, options = {}) {
            const localVarPath = `/api/accountsadmin/c/authorization/perform-sso`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (forDomain !== undefined) {
                localVarQueryParameter["forDomain"] = forDomain;
            }
            if (redirectUrl !== undefined) {
                localVarQueryParameter["redirectUrl"] = redirectUrl;
            }
            if (loginPath !== undefined) {
                localVarQueryParameter["loginPath"] = loginPath;
            }
            if (omitRedirectUrlWhenNeedAuth !== undefined) {
                localVarQueryParameter["omitRedirectUrlWhenNeedAuth"] =
                    omitRedirectUrlWhenNeedAuth;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {SelectAccountDto} selectAccountDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectAccount(selectAccountDto, options = {}) {
            // verify required parameter 'selectAccountDto' is not null or undefined
            if (selectAccountDto === null || selectAccountDto === undefined) {
                throw new RequiredError("selectAccountDto", "Required parameter selectAccountDto was null or undefined when calling selectAccount.");
            }
            const localVarPath = `/api/accountsadmin/c/authorization/select-account`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof selectAccountDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(selectAccountDto !== undefined ? selectAccountDto : {})
                : selectAccountDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {TokenAndCodeVerifierDto} tokenAndCodeVerifierDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        thirdPartyCreateSession(tokenAndCodeVerifierDto, options = {}) {
            // verify required parameter 'tokenAndCodeVerifierDto' is not null or undefined
            if (tokenAndCodeVerifierDto === null ||
                tokenAndCodeVerifierDto === undefined) {
                throw new RequiredError("tokenAndCodeVerifierDto", "Required parameter tokenAndCodeVerifierDto was null or undefined when calling thirdPartyCreateSession.");
            }
            const localVarPath = `/api/accountsadmin/public/authorization/third-party/create-session`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof tokenAndCodeVerifierDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(tokenAndCodeVerifierDto !== undefined
                    ? tokenAndCodeVerifierDto
                    : {})
                : tokenAndCodeVerifierDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {CodeChallengeDto} codeChallengeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        thirdPartyCreateToken(codeChallengeDto, options = {}) {
            // verify required parameter 'codeChallengeDto' is not null or undefined
            if (codeChallengeDto === null || codeChallengeDto === undefined) {
                throw new RequiredError("codeChallengeDto", "Required parameter codeChallengeDto was null or undefined when calling thirdPartyCreateToken.");
            }
            const localVarPath = `/api/accountsadmin/c/authorization/third-party/create-token`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof codeChallengeDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(codeChallengeDto !== undefined ? codeChallengeDto : {})
                : codeChallengeDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AuthorizationEndpointApi - functional programming interface
 * @export
 */
export const AuthorizationEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {TokenAndRedirectUrlDto} params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authAndRedirect(params, options) {
            const localVarAxiosArgs = AuthorizationEndpointApiAxiosParamCreator(configuration).authAndRedirect(params, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {AutoLoginDto} autoLoginDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoLogin(autoLoginDto, options) {
            const localVarAxiosArgs = AuthorizationEndpointApiAxiosParamCreator(configuration).autoLogin(autoLoginDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {ChangeAccountDto} changeAccountDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccount(changeAccountDto, options) {
            const localVarAxiosArgs = AuthorizationEndpointApiAxiosParamCreator(configuration).changeAccount(changeAccountDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {string} [forDomain]
         * @param {string} [redirectUrl]
         * @param {string} [loginPath]
         * @param {boolean} [omitRedirectUrlWhenNeedAuth]
         * @param {number} [accountId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountAndRedirect(forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, accountId, options) {
            const localVarAxiosArgs = AuthorizationEndpointApiAxiosParamCreator(configuration).changeAccountAndRedirect(forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {UserMode} userMode
         * @param {string} [forDomain]
         * @param {string} [redirectUrl]
         * @param {string} [loginPath]
         * @param {boolean} [omitRedirectUrlWhenNeedAuth]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeModeAndRedirect(userMode, forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, options) {
            const localVarAxiosArgs = AuthorizationEndpointApiAxiosParamCreator(configuration).changeModeAndRedirect(userMode, forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkIsUserAuthorized(options) {
            const localVarAxiosArgs = AuthorizationEndpointApiAxiosParamCreator(configuration).checkIsUserAuthorized(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {string} [forDomain]
         * @param {string} [redirectUrl]
         * @param {string} [loginPath]
         * @param {boolean} [omitRedirectUrlWhenNeedAuth]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        impersonateUserAndRedirect(userId, forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, options) {
            const localVarAxiosArgs = AuthorizationEndpointApiAxiosParamCreator(configuration).impersonateUserAndRedirect(userId, forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {LoginDto} loginDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginDto, options) {
            const localVarAxiosArgs = AuthorizationEndpointApiAxiosParamCreator(configuration).login(loginDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout1(options) {
            const localVarAxiosArgs = AuthorizationEndpointApiAxiosParamCreator(configuration).logout1(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {string} [forDomain]
         * @param {string} [redirectUrl]
         * @param {string} [loginPath]
         * @param {boolean} [omitRedirectUrlWhenNeedAuth]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performSSO(forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, options) {
            const localVarAxiosArgs = AuthorizationEndpointApiAxiosParamCreator(configuration).performSSO(forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {SelectAccountDto} selectAccountDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectAccount(selectAccountDto, options) {
            const localVarAxiosArgs = AuthorizationEndpointApiAxiosParamCreator(configuration).selectAccount(selectAccountDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {TokenAndCodeVerifierDto} tokenAndCodeVerifierDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        thirdPartyCreateSession(tokenAndCodeVerifierDto, options) {
            const localVarAxiosArgs = AuthorizationEndpointApiAxiosParamCreator(configuration).thirdPartyCreateSession(tokenAndCodeVerifierDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {CodeChallengeDto} codeChallengeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        thirdPartyCreateToken(codeChallengeDto, options) {
            const localVarAxiosArgs = AuthorizationEndpointApiAxiosParamCreator(configuration).thirdPartyCreateToken(codeChallengeDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AuthorizationEndpointApi - factory interface
 * @export
 */
export const AuthorizationEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {TokenAndRedirectUrlDto} params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authAndRedirect(params, options) {
            return AuthorizationEndpointApiFp(configuration).authAndRedirect(params, options)(axios, basePath);
        },
        /**
         *
         * @param {AutoLoginDto} autoLoginDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoLogin(autoLoginDto, options) {
            return AuthorizationEndpointApiFp(configuration).autoLogin(autoLoginDto, options)(axios, basePath);
        },
        /**
         *
         * @param {ChangeAccountDto} changeAccountDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccount(changeAccountDto, options) {
            return AuthorizationEndpointApiFp(configuration).changeAccount(changeAccountDto, options)(axios, basePath);
        },
        /**
         *
         * @param {string} [forDomain]
         * @param {string} [redirectUrl]
         * @param {string} [loginPath]
         * @param {boolean} [omitRedirectUrlWhenNeedAuth]
         * @param {number} [accountId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountAndRedirect(forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, accountId, options) {
            return AuthorizationEndpointApiFp(configuration).changeAccountAndRedirect(forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, accountId, options)(axios, basePath);
        },
        /**
         *
         * @param {UserMode} userMode
         * @param {string} [forDomain]
         * @param {string} [redirectUrl]
         * @param {string} [loginPath]
         * @param {boolean} [omitRedirectUrlWhenNeedAuth]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeModeAndRedirect(userMode, forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, options) {
            return AuthorizationEndpointApiFp(configuration).changeModeAndRedirect(userMode, forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, options)(axios, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkIsUserAuthorized(options) {
            return AuthorizationEndpointApiFp(configuration).checkIsUserAuthorized(options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {string} [forDomain]
         * @param {string} [redirectUrl]
         * @param {string} [loginPath]
         * @param {boolean} [omitRedirectUrlWhenNeedAuth]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        impersonateUserAndRedirect(userId, forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, options) {
            return AuthorizationEndpointApiFp(configuration).impersonateUserAndRedirect(userId, forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, options)(axios, basePath);
        },
        /**
         *
         * @param {LoginDto} loginDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginDto, options) {
            return AuthorizationEndpointApiFp(configuration).login(loginDto, options)(axios, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout1(options) {
            return AuthorizationEndpointApiFp(configuration).logout1(options)(axios, basePath);
        },
        /**
         *
         * @param {string} [forDomain]
         * @param {string} [redirectUrl]
         * @param {string} [loginPath]
         * @param {boolean} [omitRedirectUrlWhenNeedAuth]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performSSO(forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, options) {
            return AuthorizationEndpointApiFp(configuration).performSSO(forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, options)(axios, basePath);
        },
        /**
         *
         * @param {SelectAccountDto} selectAccountDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectAccount(selectAccountDto, options) {
            return AuthorizationEndpointApiFp(configuration).selectAccount(selectAccountDto, options)(axios, basePath);
        },
        /**
         *
         * @param {TokenAndCodeVerifierDto} tokenAndCodeVerifierDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        thirdPartyCreateSession(tokenAndCodeVerifierDto, options) {
            return AuthorizationEndpointApiFp(configuration).thirdPartyCreateSession(tokenAndCodeVerifierDto, options)(axios, basePath);
        },
        /**
         *
         * @param {CodeChallengeDto} codeChallengeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        thirdPartyCreateToken(codeChallengeDto, options) {
            return AuthorizationEndpointApiFp(configuration).thirdPartyCreateToken(codeChallengeDto, options)(axios, basePath);
        },
    };
};
/**
 * AuthorizationEndpointApi - object-oriented interface
 * @export
 * @class AuthorizationEndpointApi
 * @extends {BaseAPI}
 */
export class AuthorizationEndpointApi extends BaseAPI {
    /**
     *
     * @param {TokenAndRedirectUrlDto} params
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationEndpointApi
     */
    authAndRedirect(params, options) {
        return AuthorizationEndpointApiFp(this.configuration).authAndRedirect(params, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {AutoLoginDto} autoLoginDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationEndpointApi
     */
    autoLogin(autoLoginDto, options) {
        return AuthorizationEndpointApiFp(this.configuration).autoLogin(autoLoginDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {ChangeAccountDto} changeAccountDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationEndpointApi
     */
    changeAccount(changeAccountDto, options) {
        return AuthorizationEndpointApiFp(this.configuration).changeAccount(changeAccountDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {string} [forDomain]
     * @param {string} [redirectUrl]
     * @param {string} [loginPath]
     * @param {boolean} [omitRedirectUrlWhenNeedAuth]
     * @param {number} [accountId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationEndpointApi
     */
    changeAccountAndRedirect(forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, accountId, options) {
        return AuthorizationEndpointApiFp(this.configuration).changeAccountAndRedirect(forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {UserMode} userMode
     * @param {string} [forDomain]
     * @param {string} [redirectUrl]
     * @param {string} [loginPath]
     * @param {boolean} [omitRedirectUrlWhenNeedAuth]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationEndpointApi
     */
    changeModeAndRedirect(userMode, forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, options) {
        return AuthorizationEndpointApiFp(this.configuration).changeModeAndRedirect(userMode, forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationEndpointApi
     */
    checkIsUserAuthorized(options) {
        return AuthorizationEndpointApiFp(this.configuration).checkIsUserAuthorized(options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} userId
     * @param {string} [forDomain]
     * @param {string} [redirectUrl]
     * @param {string} [loginPath]
     * @param {boolean} [omitRedirectUrlWhenNeedAuth]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationEndpointApi
     */
    impersonateUserAndRedirect(userId, forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, options) {
        return AuthorizationEndpointApiFp(this.configuration).impersonateUserAndRedirect(userId, forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {LoginDto} loginDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationEndpointApi
     */
    login(loginDto, options) {
        return AuthorizationEndpointApiFp(this.configuration).login(loginDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationEndpointApi
     */
    logout1(options) {
        return AuthorizationEndpointApiFp(this.configuration).logout1(options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {string} [forDomain]
     * @param {string} [redirectUrl]
     * @param {string} [loginPath]
     * @param {boolean} [omitRedirectUrlWhenNeedAuth]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationEndpointApi
     */
    performSSO(forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, options) {
        return AuthorizationEndpointApiFp(this.configuration).performSSO(forDomain, redirectUrl, loginPath, omitRedirectUrlWhenNeedAuth, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {SelectAccountDto} selectAccountDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationEndpointApi
     */
    selectAccount(selectAccountDto, options) {
        return AuthorizationEndpointApiFp(this.configuration).selectAccount(selectAccountDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {TokenAndCodeVerifierDto} tokenAndCodeVerifierDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationEndpointApi
     */
    thirdPartyCreateSession(tokenAndCodeVerifierDto, options) {
        return AuthorizationEndpointApiFp(this.configuration).thirdPartyCreateSession(tokenAndCodeVerifierDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {CodeChallengeDto} codeChallengeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationEndpointApi
     */
    thirdPartyCreateToken(codeChallengeDto, options) {
        return AuthorizationEndpointApiFp(this.configuration).thirdPartyCreateToken(codeChallengeDto, options)(this.axios, this.basePath);
    }
}
/**
 * CustomRolesEndpointApi - axios parameter creator
 * @export
 */
export const CustomRolesEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {RoleCreateOrUpdateDto} roleCreateOrUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCustomRole(roleCreateOrUpdateDto, options = {}) {
            // verify required parameter 'roleCreateOrUpdateDto' is not null or undefined
            if (roleCreateOrUpdateDto === null ||
                roleCreateOrUpdateDto === undefined) {
                throw new RequiredError("roleCreateOrUpdateDto", "Required parameter roleCreateOrUpdateDto was null or undefined when calling addCustomRole.");
            }
            const localVarPath = `/api/accountsadmin/c/sadmin/roles/custom`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof roleCreateOrUpdateDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(roleCreateOrUpdateDto !== undefined ? roleCreateOrUpdateDto : {})
                : roleCreateOrUpdateDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {RoleDeleteDto} roleDeleteDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomRole(roleDeleteDto, options = {}) {
            // verify required parameter 'roleDeleteDto' is not null or undefined
            if (roleDeleteDto === null || roleDeleteDto === undefined) {
                throw new RequiredError("roleDeleteDto", "Required parameter roleDeleteDto was null or undefined when calling deleteCustomRole.");
            }
            const localVarPath = `/api/accountsadmin/c/sadmin/roles/custom`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof roleDeleteDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(roleDeleteDto !== undefined ? roleDeleteDto : {})
                : roleDeleteDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [accountId]
         * @param {boolean} [globalOnly]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomRoles(accountId, globalOnly, options = {}) {
            const localVarPath = `/api/accountsadmin/c/sadmin/roles/custom`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (accountId !== undefined) {
                localVarQueryParameter["accountId"] = accountId;
            }
            if (globalOnly !== undefined) {
                localVarQueryParameter["globalOnly"] = globalOnly;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {RoleRenameDto} roleRenameDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameCustomRole(roleRenameDto, options = {}) {
            // verify required parameter 'roleRenameDto' is not null or undefined
            if (roleRenameDto === null || roleRenameDto === undefined) {
                throw new RequiredError("roleRenameDto", "Required parameter roleRenameDto was null or undefined when calling renameCustomRole.");
            }
            const localVarPath = `/api/accountsadmin/c/sadmin/roles/custom/rename`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof roleRenameDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(roleRenameDto !== undefined ? roleRenameDto : {})
                : roleRenameDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {RoleCreateOrUpdateDto} roleCreateOrUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomRole(roleCreateOrUpdateDto, options = {}) {
            // verify required parameter 'roleCreateOrUpdateDto' is not null or undefined
            if (roleCreateOrUpdateDto === null ||
                roleCreateOrUpdateDto === undefined) {
                throw new RequiredError("roleCreateOrUpdateDto", "Required parameter roleCreateOrUpdateDto was null or undefined when calling updateCustomRole.");
            }
            const localVarPath = `/api/accountsadmin/c/sadmin/roles/custom`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof roleCreateOrUpdateDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(roleCreateOrUpdateDto !== undefined ? roleCreateOrUpdateDto : {})
                : roleCreateOrUpdateDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * CustomRolesEndpointApi - functional programming interface
 * @export
 */
export const CustomRolesEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {RoleCreateOrUpdateDto} roleCreateOrUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCustomRole(roleCreateOrUpdateDto, options) {
            const localVarAxiosArgs = CustomRolesEndpointApiAxiosParamCreator(configuration).addCustomRole(roleCreateOrUpdateDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {RoleDeleteDto} roleDeleteDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomRole(roleDeleteDto, options) {
            const localVarAxiosArgs = CustomRolesEndpointApiAxiosParamCreator(configuration).deleteCustomRole(roleDeleteDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} [accountId]
         * @param {boolean} [globalOnly]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomRoles(accountId, globalOnly, options) {
            const localVarAxiosArgs = CustomRolesEndpointApiAxiosParamCreator(configuration).listCustomRoles(accountId, globalOnly, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {RoleRenameDto} roleRenameDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameCustomRole(roleRenameDto, options) {
            const localVarAxiosArgs = CustomRolesEndpointApiAxiosParamCreator(configuration).renameCustomRole(roleRenameDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {RoleCreateOrUpdateDto} roleCreateOrUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomRole(roleCreateOrUpdateDto, options) {
            const localVarAxiosArgs = CustomRolesEndpointApiAxiosParamCreator(configuration).updateCustomRole(roleCreateOrUpdateDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * CustomRolesEndpointApi - factory interface
 * @export
 */
export const CustomRolesEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {RoleCreateOrUpdateDto} roleCreateOrUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCustomRole(roleCreateOrUpdateDto, options) {
            return CustomRolesEndpointApiFp(configuration).addCustomRole(roleCreateOrUpdateDto, options)(axios, basePath);
        },
        /**
         *
         * @param {RoleDeleteDto} roleDeleteDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomRole(roleDeleteDto, options) {
            return CustomRolesEndpointApiFp(configuration).deleteCustomRole(roleDeleteDto, options)(axios, basePath);
        },
        /**
         *
         * @param {number} [accountId]
         * @param {boolean} [globalOnly]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomRoles(accountId, globalOnly, options) {
            return CustomRolesEndpointApiFp(configuration).listCustomRoles(accountId, globalOnly, options)(axios, basePath);
        },
        /**
         *
         * @param {RoleRenameDto} roleRenameDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameCustomRole(roleRenameDto, options) {
            return CustomRolesEndpointApiFp(configuration).renameCustomRole(roleRenameDto, options)(axios, basePath);
        },
        /**
         *
         * @param {RoleCreateOrUpdateDto} roleCreateOrUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomRole(roleCreateOrUpdateDto, options) {
            return CustomRolesEndpointApiFp(configuration).updateCustomRole(roleCreateOrUpdateDto, options)(axios, basePath);
        },
    };
};
/**
 * CustomRolesEndpointApi - object-oriented interface
 * @export
 * @class CustomRolesEndpointApi
 * @extends {BaseAPI}
 */
export class CustomRolesEndpointApi extends BaseAPI {
    /**
     *
     * @param {RoleCreateOrUpdateDto} roleCreateOrUpdateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomRolesEndpointApi
     */
    addCustomRole(roleCreateOrUpdateDto, options) {
        return CustomRolesEndpointApiFp(this.configuration).addCustomRole(roleCreateOrUpdateDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {RoleDeleteDto} roleDeleteDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomRolesEndpointApi
     */
    deleteCustomRole(roleDeleteDto, options) {
        return CustomRolesEndpointApiFp(this.configuration).deleteCustomRole(roleDeleteDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} [accountId]
     * @param {boolean} [globalOnly]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomRolesEndpointApi
     */
    listCustomRoles(accountId, globalOnly, options) {
        return CustomRolesEndpointApiFp(this.configuration).listCustomRoles(accountId, globalOnly, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {RoleRenameDto} roleRenameDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomRolesEndpointApi
     */
    renameCustomRole(roleRenameDto, options) {
        return CustomRolesEndpointApiFp(this.configuration).renameCustomRole(roleRenameDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {RoleCreateOrUpdateDto} roleCreateOrUpdateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomRolesEndpointApi
     */
    updateCustomRole(roleCreateOrUpdateDto, options) {
        return CustomRolesEndpointApiFp(this.configuration).updateCustomRole(roleCreateOrUpdateDto, options)(this.axios, this.basePath);
    }
}
/**
 * EmailVerificationEndpointApi - axios parameter creator
 * @export
 */
export const EmailVerificationEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerificationEmailInfo(options = {}) {
            const localVarPath = `/api/accountsadmin/c/verification/verification-email-info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {SendVerificationEmailDto} sendVerificationEmailDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendVerificationMail(sendVerificationEmailDto, options = {}) {
            // verify required parameter 'sendVerificationEmailDto' is not null or undefined
            if (sendVerificationEmailDto === null ||
                sendVerificationEmailDto === undefined) {
                throw new RequiredError("sendVerificationEmailDto", "Required parameter sendVerificationEmailDto was null or undefined when calling sendVerificationMail.");
            }
            const localVarPath = `/api/accountsadmin/c/verification/send-verification-mail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof sendVerificationEmailDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(sendVerificationEmailDto !== undefined
                    ? sendVerificationEmailDto
                    : {})
                : sendVerificationEmailDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * EmailVerificationEndpointApi - functional programming interface
 * @export
 */
export const EmailVerificationEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerificationEmailInfo(options) {
            const localVarAxiosArgs = EmailVerificationEndpointApiAxiosParamCreator(configuration).getVerificationEmailInfo(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {SendVerificationEmailDto} sendVerificationEmailDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendVerificationMail(sendVerificationEmailDto, options) {
            const localVarAxiosArgs = EmailVerificationEndpointApiAxiosParamCreator(configuration).sendVerificationMail(sendVerificationEmailDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * EmailVerificationEndpointApi - factory interface
 * @export
 */
export const EmailVerificationEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerificationEmailInfo(options) {
            return EmailVerificationEndpointApiFp(configuration).getVerificationEmailInfo(options)(axios, basePath);
        },
        /**
         *
         * @param {SendVerificationEmailDto} sendVerificationEmailDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendVerificationMail(sendVerificationEmailDto, options) {
            return EmailVerificationEndpointApiFp(configuration).sendVerificationMail(sendVerificationEmailDto, options)(axios, basePath);
        },
    };
};
/**
 * EmailVerificationEndpointApi - object-oriented interface
 * @export
 * @class EmailVerificationEndpointApi
 * @extends {BaseAPI}
 */
export class EmailVerificationEndpointApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailVerificationEndpointApi
     */
    getVerificationEmailInfo(options) {
        return EmailVerificationEndpointApiFp(this.configuration).getVerificationEmailInfo(options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {SendVerificationEmailDto} sendVerificationEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailVerificationEndpointApi
     */
    sendVerificationMail(sendVerificationEmailDto, options) {
        return EmailVerificationEndpointApiFp(this.configuration).sendVerificationMail(sendVerificationEmailDto, options)(this.axios, this.basePath);
    }
}
/**
 * ExternalSsoEndpointApi - axios parameter creator
 * @export
 */
export const ExternalSsoEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {UserEmailDto} userEmailDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkExternalSsoAvailabilityByEmail(userEmailDto, options = {}) {
            // verify required parameter 'userEmailDto' is not null or undefined
            if (userEmailDto === null || userEmailDto === undefined) {
                throw new RequiredError("userEmailDto", "Required parameter userEmailDto was null or undefined when calling checkExternalSsoAvailabilityByEmail.");
            }
            const localVarPath = `/api/accountsadmin/public/authorization/external-sso/check-by-email`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof userEmailDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(userEmailDto !== undefined ? userEmailDto : {})
                : userEmailDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalSsoUserForbiddenError(options = {}) {
            const localVarPath = `/api/accountsadmin/public/authorization/external-sso/get-user-forbidden-error`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ExternalSsoEndpointApi - functional programming interface
 * @export
 */
export const ExternalSsoEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {UserEmailDto} userEmailDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkExternalSsoAvailabilityByEmail(userEmailDto, options) {
            const localVarAxiosArgs = ExternalSsoEndpointApiAxiosParamCreator(configuration).checkExternalSsoAvailabilityByEmail(userEmailDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalSsoUserForbiddenError(options) {
            const localVarAxiosArgs = ExternalSsoEndpointApiAxiosParamCreator(configuration).getExternalSsoUserForbiddenError(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * ExternalSsoEndpointApi - factory interface
 * @export
 */
export const ExternalSsoEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {UserEmailDto} userEmailDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkExternalSsoAvailabilityByEmail(userEmailDto, options) {
            return ExternalSsoEndpointApiFp(configuration).checkExternalSsoAvailabilityByEmail(userEmailDto, options)(axios, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalSsoUserForbiddenError(options) {
            return ExternalSsoEndpointApiFp(configuration).getExternalSsoUserForbiddenError(options)(axios, basePath);
        },
    };
};
/**
 * ExternalSsoEndpointApi - object-oriented interface
 * @export
 * @class ExternalSsoEndpointApi
 * @extends {BaseAPI}
 */
export class ExternalSsoEndpointApi extends BaseAPI {
    /**
     *
     * @param {UserEmailDto} userEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalSsoEndpointApi
     */
    checkExternalSsoAvailabilityByEmail(userEmailDto, options) {
        return ExternalSsoEndpointApiFp(this.configuration).checkExternalSsoAvailabilityByEmail(userEmailDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalSsoEndpointApi
     */
    getExternalSsoUserForbiddenError(options) {
        return ExternalSsoEndpointApiFp(this.configuration).getExternalSsoUserForbiddenError(options)(this.axios, this.basePath);
    }
}
/**
 * FeatureEndpointApi - axios parameter creator
 * @export
 */
export const FeatureEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectFeatures(options = {}) {
            const localVarPath = `/api/accountsadmin/c/sadmin/features/project/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSystemFeatures(options = {}) {
            const localVarPath = `/api/accountsadmin/c/sadmin/features/system/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * FeatureEndpointApi - functional programming interface
 * @export
 */
export const FeatureEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectFeatures(options) {
            const localVarAxiosArgs = FeatureEndpointApiAxiosParamCreator(configuration).getAllProjectFeatures(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSystemFeatures(options) {
            const localVarAxiosArgs = FeatureEndpointApiAxiosParamCreator(configuration).getAllSystemFeatures(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * FeatureEndpointApi - factory interface
 * @export
 */
export const FeatureEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectFeatures(options) {
            return FeatureEndpointApiFp(configuration).getAllProjectFeatures(options)(axios, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSystemFeatures(options) {
            return FeatureEndpointApiFp(configuration).getAllSystemFeatures(options)(axios, basePath);
        },
    };
};
/**
 * FeatureEndpointApi - object-oriented interface
 * @export
 * @class FeatureEndpointApi
 * @extends {BaseAPI}
 */
export class FeatureEndpointApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureEndpointApi
     */
    getAllProjectFeatures(options) {
        return FeatureEndpointApiFp(this.configuration).getAllProjectFeatures(options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureEndpointApi
     */
    getAllSystemFeatures(options) {
        return FeatureEndpointApiFp(this.configuration).getAllSystemFeatures(options)(this.axios, this.basePath);
    }
}
/**
 * OAuth2EndpointApi - axios parameter creator
 * @export
 */
export const OAuth2EndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} provider
         * @param {string} language
         * @param {{ [key: string]: string; }} utmParameters
         * @param {string} [product]
         * @param {string} [invitationEmail]
         * @param {number} [invitationAccountId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oAuth2PreLogin(provider, language, utmParameters, product, invitationEmail, invitationAccountId, options = {}) {
            // verify required parameter 'provider' is not null or undefined
            if (provider === null || provider === undefined) {
                throw new RequiredError("provider", "Required parameter provider was null or undefined when calling oAuth2PreLogin.");
            }
            // verify required parameter 'language' is not null or undefined
            if (language === null || language === undefined) {
                throw new RequiredError("language", "Required parameter language was null or undefined when calling oAuth2PreLogin.");
            }
            // verify required parameter 'utmParameters' is not null or undefined
            if (utmParameters === null || utmParameters === undefined) {
                throw new RequiredError("utmParameters", "Required parameter utmParameters was null or undefined when calling oAuth2PreLogin.");
            }
            const localVarPath = `/api/accountsadmin/public/authorization/oauth2/{provider}`.replace(`{${"provider"}}`, encodeURIComponent(String(provider)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (language !== undefined) {
                localVarQueryParameter["language"] = language;
            }
            if (product !== undefined) {
                localVarQueryParameter["product"] = product;
            }
            if (invitationEmail !== undefined) {
                localVarQueryParameter["invitationEmail"] = invitationEmail;
            }
            if (invitationAccountId !== undefined) {
                localVarQueryParameter["invitationAccountId"] = invitationAccountId;
            }
            if (utmParameters !== undefined) {
                localVarQueryParameter["utmParameters"] = utmParameters;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * OAuth2EndpointApi - functional programming interface
 * @export
 */
export const OAuth2EndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {string} provider
         * @param {string} language
         * @param {{ [key: string]: string; }} utmParameters
         * @param {string} [product]
         * @param {string} [invitationEmail]
         * @param {number} [invitationAccountId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oAuth2PreLogin(provider, language, utmParameters, product, invitationEmail, invitationAccountId, options) {
            const localVarAxiosArgs = OAuth2EndpointApiAxiosParamCreator(configuration).oAuth2PreLogin(provider, language, utmParameters, product, invitationEmail, invitationAccountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * OAuth2EndpointApi - factory interface
 * @export
 */
export const OAuth2EndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {string} provider
         * @param {string} language
         * @param {{ [key: string]: string; }} utmParameters
         * @param {string} [product]
         * @param {string} [invitationEmail]
         * @param {number} [invitationAccountId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oAuth2PreLogin(provider, language, utmParameters, product, invitationEmail, invitationAccountId, options) {
            return OAuth2EndpointApiFp(configuration).oAuth2PreLogin(provider, language, utmParameters, product, invitationEmail, invitationAccountId, options)(axios, basePath);
        },
    };
};
/**
 * OAuth2EndpointApi - object-oriented interface
 * @export
 * @class OAuth2EndpointApi
 * @extends {BaseAPI}
 */
export class OAuth2EndpointApi extends BaseAPI {
    /**
     *
     * @param {string} provider
     * @param {string} language
     * @param {{ [key: string]: string; }} utmParameters
     * @param {string} [product]
     * @param {string} [invitationEmail]
     * @param {number} [invitationAccountId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2EndpointApi
     */
    oAuth2PreLogin(provider, language, utmParameters, product, invitationEmail, invitationAccountId, options) {
        return OAuth2EndpointApiFp(this.configuration).oAuth2PreLogin(provider, language, utmParameters, product, invitationEmail, invitationAccountId, options)(this.axios, this.basePath);
    }
}
/**
 * OidcBackChannelLogoutEndpointApi - axios parameter creator
 * @export
 */
export const OidcBackChannelLogoutEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} registrationId
         * @param {string} [logoutToken]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(registrationId, logoutToken, options = {}) {
            // verify required parameter 'registrationId' is not null or undefined
            if (registrationId === null || registrationId === undefined) {
                throw new RequiredError("registrationId", "Required parameter registrationId was null or undefined when calling logout.");
            }
            const localVarPath = `/api/accountsadmin/public/authorization/logout/connect/back-channel/{registrationId}`.replace(`{${"registrationId"}}`, encodeURIComponent(String(registrationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (logoutToken !== undefined) {
                localVarQueryParameter["logout_token"] = logoutToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * OidcBackChannelLogoutEndpointApi - functional programming interface
 * @export
 */
export const OidcBackChannelLogoutEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {string} registrationId
         * @param {string} [logoutToken]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(registrationId, logoutToken, options) {
            const localVarAxiosArgs = OidcBackChannelLogoutEndpointApiAxiosParamCreator(configuration).logout(registrationId, logoutToken, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * OidcBackChannelLogoutEndpointApi - factory interface
 * @export
 */
export const OidcBackChannelLogoutEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {string} registrationId
         * @param {string} [logoutToken]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(registrationId, logoutToken, options) {
            return OidcBackChannelLogoutEndpointApiFp(configuration).logout(registrationId, logoutToken, options)(axios, basePath);
        },
    };
};
/**
 * OidcBackChannelLogoutEndpointApi - object-oriented interface
 * @export
 * @class OidcBackChannelLogoutEndpointApi
 * @extends {BaseAPI}
 */
export class OidcBackChannelLogoutEndpointApi extends BaseAPI {
    /**
     *
     * @param {string} registrationId
     * @param {string} [logoutToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OidcBackChannelLogoutEndpointApi
     */
    logout(registrationId, logoutToken, options) {
        return OidcBackChannelLogoutEndpointApiFp(this.configuration).logout(registrationId, logoutToken, options)(this.axios, this.basePath);
    }
}
/**
 * OptionsEndpointApi - axios parameter creator
 * @export
 */
export const OptionsEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOptions(options = {}) {
            const localVarPath = `/api/accountsadmin/public/options`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * OptionsEndpointApi - functional programming interface
 * @export
 */
export const OptionsEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOptions(options) {
            const localVarAxiosArgs = OptionsEndpointApiAxiosParamCreator(configuration).getOptions(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * OptionsEndpointApi - factory interface
 * @export
 */
export const OptionsEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOptions(options) {
            return OptionsEndpointApiFp(configuration).getOptions(options)(axios, basePath);
        },
    };
};
/**
 * OptionsEndpointApi - object-oriented interface
 * @export
 * @class OptionsEndpointApi
 * @extends {BaseAPI}
 */
export class OptionsEndpointApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionsEndpointApi
     */
    getOptions(options) {
        return OptionsEndpointApiFp(this.configuration).getOptions(options)(this.axios, this.basePath);
    }
}
/**
 * PasswordResetEndpointApi - axios parameter creator
 * @export
 */
export const PasswordResetEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {ResetPasswordDto} resetPasswordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPasswordDto, options = {}) {
            // verify required parameter 'resetPasswordDto' is not null or undefined
            if (resetPasswordDto === null || resetPasswordDto === undefined) {
                throw new RequiredError("resetPasswordDto", "Required parameter resetPasswordDto was null or undefined when calling resetPassword.");
            }
            const localVarPath = `/api/accountsadmin/c/password/reset`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof resetPasswordDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(resetPasswordDto !== undefined ? resetPasswordDto : {})
                : resetPasswordDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {SendResetPasswordDto} sendResetPasswordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendResetPasswordEmail(sendResetPasswordDto, options = {}) {
            // verify required parameter 'sendResetPasswordDto' is not null or undefined
            if (sendResetPasswordDto === null || sendResetPasswordDto === undefined) {
                throw new RequiredError("sendResetPasswordDto", "Required parameter sendResetPasswordDto was null or undefined when calling sendResetPasswordEmail.");
            }
            const localVarPath = `/api/accountsadmin/public/password/reset/send-email`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof sendResetPasswordDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(sendResetPasswordDto !== undefined ? sendResetPasswordDto : {})
                : sendResetPasswordDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * PasswordResetEndpointApi - functional programming interface
 * @export
 */
export const PasswordResetEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {ResetPasswordDto} resetPasswordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPasswordDto, options) {
            const localVarAxiosArgs = PasswordResetEndpointApiAxiosParamCreator(configuration).resetPassword(resetPasswordDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {SendResetPasswordDto} sendResetPasswordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendResetPasswordEmail(sendResetPasswordDto, options) {
            const localVarAxiosArgs = PasswordResetEndpointApiAxiosParamCreator(configuration).sendResetPasswordEmail(sendResetPasswordDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * PasswordResetEndpointApi - factory interface
 * @export
 */
export const PasswordResetEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {ResetPasswordDto} resetPasswordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPasswordDto, options) {
            return PasswordResetEndpointApiFp(configuration).resetPassword(resetPasswordDto, options)(axios, basePath);
        },
        /**
         *
         * @param {SendResetPasswordDto} sendResetPasswordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendResetPasswordEmail(sendResetPasswordDto, options) {
            return PasswordResetEndpointApiFp(configuration).sendResetPasswordEmail(sendResetPasswordDto, options)(axios, basePath);
        },
    };
};
/**
 * PasswordResetEndpointApi - object-oriented interface
 * @export
 * @class PasswordResetEndpointApi
 * @extends {BaseAPI}
 */
export class PasswordResetEndpointApi extends BaseAPI {
    /**
     *
     * @param {ResetPasswordDto} resetPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetEndpointApi
     */
    resetPassword(resetPasswordDto, options) {
        return PasswordResetEndpointApiFp(this.configuration).resetPassword(resetPasswordDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {SendResetPasswordDto} sendResetPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetEndpointApi
     */
    sendResetPasswordEmail(sendResetPasswordDto, options) {
        return PasswordResetEndpointApiFp(this.configuration).sendResetPasswordEmail(sendResetPasswordDto, options)(this.axios, this.basePath);
    }
}
/**
 * RegistrationEndpointApi - axios parameter creator
 * @export
 */
export const RegistrationEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectCountryIsoCode(options = {}) {
            const localVarPath = `/api/accountsadmin/public/registration/detect-country-iso-code`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {RegistrationRequestDto} registrationRequestDto
         * @param {string} [CAPTCHA_BYPASS_TOKEN]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(registrationRequestDto, CAPTCHA_BYPASS_TOKEN, options = {}) {
            // verify required parameter 'registrationRequestDto' is not null or undefined
            if (registrationRequestDto === null ||
                registrationRequestDto === undefined) {
                throw new RequiredError("registrationRequestDto", "Required parameter registrationRequestDto was null or undefined when calling register.");
            }
            const localVarPath = `/api/accountsadmin/public/registration/register`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof registrationRequestDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(registrationRequestDto !== undefined ? registrationRequestDto : {})
                : registrationRequestDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {RegistrationWithInvitationRequestDto} registrationWithInvitationRequestDto
         * @param {string} [CAPTCHA_BYPASS_TOKEN]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerWithInvitation(registrationWithInvitationRequestDto, CAPTCHA_BYPASS_TOKEN, options = {}) {
            // verify required parameter 'registrationWithInvitationRequestDto' is not null or undefined
            if (registrationWithInvitationRequestDto === null ||
                registrationWithInvitationRequestDto === undefined) {
                throw new RequiredError("registrationWithInvitationRequestDto", "Required parameter registrationWithInvitationRequestDto was null or undefined when calling registerWithInvitation.");
            }
            const localVarPath = `/api/accountsadmin/public/registration/register-with-invitation`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof registrationWithInvitationRequestDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(registrationWithInvitationRequestDto !== undefined
                    ? registrationWithInvitationRequestDto
                    : {})
                : registrationWithInvitationRequestDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * RegistrationEndpointApi - functional programming interface
 * @export
 */
export const RegistrationEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectCountryIsoCode(options) {
            const localVarAxiosArgs = RegistrationEndpointApiAxiosParamCreator(configuration).detectCountryIsoCode(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {RegistrationRequestDto} registrationRequestDto
         * @param {string} [CAPTCHA_BYPASS_TOKEN]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(registrationRequestDto, CAPTCHA_BYPASS_TOKEN, options) {
            const localVarAxiosArgs = RegistrationEndpointApiAxiosParamCreator(configuration).register(registrationRequestDto, CAPTCHA_BYPASS_TOKEN, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {RegistrationWithInvitationRequestDto} registrationWithInvitationRequestDto
         * @param {string} [CAPTCHA_BYPASS_TOKEN]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerWithInvitation(registrationWithInvitationRequestDto, CAPTCHA_BYPASS_TOKEN, options) {
            const localVarAxiosArgs = RegistrationEndpointApiAxiosParamCreator(configuration).registerWithInvitation(registrationWithInvitationRequestDto, CAPTCHA_BYPASS_TOKEN, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * RegistrationEndpointApi - factory interface
 * @export
 */
export const RegistrationEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectCountryIsoCode(options) {
            return RegistrationEndpointApiFp(configuration).detectCountryIsoCode(options)(axios, basePath);
        },
        /**
         *
         * @param {RegistrationRequestDto} registrationRequestDto
         * @param {string} [CAPTCHA_BYPASS_TOKEN]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(registrationRequestDto, CAPTCHA_BYPASS_TOKEN, options) {
            return RegistrationEndpointApiFp(configuration).register(registrationRequestDto, CAPTCHA_BYPASS_TOKEN, options)(axios, basePath);
        },
        /**
         *
         * @param {RegistrationWithInvitationRequestDto} registrationWithInvitationRequestDto
         * @param {string} [CAPTCHA_BYPASS_TOKEN]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerWithInvitation(registrationWithInvitationRequestDto, CAPTCHA_BYPASS_TOKEN, options) {
            return RegistrationEndpointApiFp(configuration).registerWithInvitation(registrationWithInvitationRequestDto, CAPTCHA_BYPASS_TOKEN, options)(axios, basePath);
        },
    };
};
/**
 * RegistrationEndpointApi - object-oriented interface
 * @export
 * @class RegistrationEndpointApi
 * @extends {BaseAPI}
 */
export class RegistrationEndpointApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationEndpointApi
     */
    detectCountryIsoCode(options) {
        return RegistrationEndpointApiFp(this.configuration).detectCountryIsoCode(options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {RegistrationRequestDto} registrationRequestDto
     * @param {string} [CAPTCHA_BYPASS_TOKEN]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationEndpointApi
     */
    register(registrationRequestDto, CAPTCHA_BYPASS_TOKEN, options) {
        return RegistrationEndpointApiFp(this.configuration).register(registrationRequestDto, CAPTCHA_BYPASS_TOKEN, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {RegistrationWithInvitationRequestDto} registrationWithInvitationRequestDto
     * @param {string} [CAPTCHA_BYPASS_TOKEN]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationEndpointApi
     */
    registerWithInvitation(registrationWithInvitationRequestDto, CAPTCHA_BYPASS_TOKEN, options) {
        return RegistrationEndpointApiFp(this.configuration).registerWithInvitation(registrationWithInvitationRequestDto, CAPTCHA_BYPASS_TOKEN, options)(this.axios, this.basePath);
    }
}
/**
 * RolesEndpointApi - axios parameter creator
 * @export
 */
export const RolesEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {number} userId
         * @param {RolesChangeDto} rolesChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeNotEditableRoles(accountId, userId, rolesChangeDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling changeNotEditableRoles.");
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError("userId", "Required parameter userId was null or undefined when calling changeNotEditableRoles.");
            }
            // verify required parameter 'rolesChangeDto' is not null or undefined
            if (rolesChangeDto === null || rolesChangeDto === undefined) {
                throw new RequiredError("rolesChangeDto", "Required parameter rolesChangeDto was null or undefined when calling changeNotEditableRoles.");
            }
            const localVarPath = `/api/accountsadmin/accounts/{accountId}/users/{userId}/roles/not-editable/change`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof rolesChangeDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(rolesChangeDto !== undefined ? rolesChangeDto : {})
                : rolesChangeDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {number} userId
         * @param {RolesChangeDto} rolesChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeRoles(accountId, userId, rolesChangeDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling changeRoles.");
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError("userId", "Required parameter userId was null or undefined when calling changeRoles.");
            }
            // verify required parameter 'rolesChangeDto' is not null or undefined
            if (rolesChangeDto === null || rolesChangeDto === undefined) {
                throw new RequiredError("rolesChangeDto", "Required parameter rolesChangeDto was null or undefined when calling changeRoles.");
            }
            const localVarPath = `/api/accountsadmin/accounts/{accountId}/users/{userId}/roles/change`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof rolesChangeDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(rolesChangeDto !== undefined ? rolesChangeDto : {})
                : rolesChangeDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalRoles(options = {}) {
            const localVarPath = `/api/accountsadmin/c/sadmin/roles/internal`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleGroupsAvailableToAccount(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getRoleGroupsAvailableToAccount.");
            }
            const localVarPath = `/api/accountsadmin/c/accounts/{accountId}/available-role-groups`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolesAvailableToAccount(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getRolesAvailableToAccount.");
            }
            const localVarPath = `/api/accountsadmin/c/accounts/{accountId}/available-roles`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * RolesEndpointApi - functional programming interface
 * @export
 */
export const RolesEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {number} userId
         * @param {RolesChangeDto} rolesChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeNotEditableRoles(accountId, userId, rolesChangeDto, options) {
            const localVarAxiosArgs = RolesEndpointApiAxiosParamCreator(configuration).changeNotEditableRoles(accountId, userId, rolesChangeDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {number} userId
         * @param {RolesChangeDto} rolesChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeRoles(accountId, userId, rolesChangeDto, options) {
            const localVarAxiosArgs = RolesEndpointApiAxiosParamCreator(configuration).changeRoles(accountId, userId, rolesChangeDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalRoles(options) {
            const localVarAxiosArgs = RolesEndpointApiAxiosParamCreator(configuration).getInternalRoles(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleGroupsAvailableToAccount(accountId, options) {
            const localVarAxiosArgs = RolesEndpointApiAxiosParamCreator(configuration).getRoleGroupsAvailableToAccount(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolesAvailableToAccount(accountId, options) {
            const localVarAxiosArgs = RolesEndpointApiAxiosParamCreator(configuration).getRolesAvailableToAccount(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * RolesEndpointApi - factory interface
 * @export
 */
export const RolesEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {number} userId
         * @param {RolesChangeDto} rolesChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeNotEditableRoles(accountId, userId, rolesChangeDto, options) {
            return RolesEndpointApiFp(configuration).changeNotEditableRoles(accountId, userId, rolesChangeDto, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {number} userId
         * @param {RolesChangeDto} rolesChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeRoles(accountId, userId, rolesChangeDto, options) {
            return RolesEndpointApiFp(configuration).changeRoles(accountId, userId, rolesChangeDto, options)(axios, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalRoles(options) {
            return RolesEndpointApiFp(configuration).getInternalRoles(options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleGroupsAvailableToAccount(accountId, options) {
            return RolesEndpointApiFp(configuration).getRoleGroupsAvailableToAccount(accountId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolesAvailableToAccount(accountId, options) {
            return RolesEndpointApiFp(configuration).getRolesAvailableToAccount(accountId, options)(axios, basePath);
        },
    };
};
/**
 * RolesEndpointApi - object-oriented interface
 * @export
 * @class RolesEndpointApi
 * @extends {BaseAPI}
 */
export class RolesEndpointApi extends BaseAPI {
    /**
     *
     * @param {number} accountId
     * @param {number} userId
     * @param {RolesChangeDto} rolesChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesEndpointApi
     */
    changeNotEditableRoles(accountId, userId, rolesChangeDto, options) {
        return RolesEndpointApiFp(this.configuration).changeNotEditableRoles(accountId, userId, rolesChangeDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId
     * @param {number} userId
     * @param {RolesChangeDto} rolesChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesEndpointApi
     */
    changeRoles(accountId, userId, rolesChangeDto, options) {
        return RolesEndpointApiFp(this.configuration).changeRoles(accountId, userId, rolesChangeDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesEndpointApi
     */
    getInternalRoles(options) {
        return RolesEndpointApiFp(this.configuration).getInternalRoles(options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesEndpointApi
     */
    getRoleGroupsAvailableToAccount(accountId, options) {
        return RolesEndpointApiFp(this.configuration).getRoleGroupsAvailableToAccount(accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesEndpointApi
     */
    getRolesAvailableToAccount(accountId, options) {
        return RolesEndpointApiFp(this.configuration).getRolesAvailableToAccount(accountId, options)(this.axios, this.basePath);
    }
}
/**
 * SmsCodeEndpointApi - axios parameter creator
 * @export
 */
export const SmsCodeEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Send sms code for authorization
         * @param {SendSmsCodeDto} sendSmsCodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSmsCodeForAuthorization(sendSmsCodeDto, options = {}) {
            // verify required parameter 'sendSmsCodeDto' is not null or undefined
            if (sendSmsCodeDto === null || sendSmsCodeDto === undefined) {
                throw new RequiredError("sendSmsCodeDto", "Required parameter sendSmsCodeDto was null or undefined when calling sendSmsCodeForAuthorization.");
            }
            const localVarPath = `/api/accountsadmin/public/sms-code/send/authorization`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof sendSmsCodeDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(sendSmsCodeDto !== undefined ? sendSmsCodeDto : {})
                : sendSmsCodeDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send sms code for verification
         * @param {SendSmsCodeDto} sendSmsCodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSmsCodeForPhoneVerification(sendSmsCodeDto, options = {}) {
            // verify required parameter 'sendSmsCodeDto' is not null or undefined
            if (sendSmsCodeDto === null || sendSmsCodeDto === undefined) {
                throw new RequiredError("sendSmsCodeDto", "Required parameter sendSmsCodeDto was null or undefined when calling sendSmsCodeForPhoneVerification.");
            }
            const localVarPath = `/api/accountsadmin/c/sms-code/send/phone-verification`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof sendSmsCodeDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(sendSmsCodeDto !== undefined ? sendSmsCodeDto : {})
                : sendSmsCodeDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verify phone by entered sms code
         * @param {VerifySmsCodeDto} verifySmsCodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifySmsCode(verifySmsCodeDto, options = {}) {
            // verify required parameter 'verifySmsCodeDto' is not null or undefined
            if (verifySmsCodeDto === null || verifySmsCodeDto === undefined) {
                throw new RequiredError("verifySmsCodeDto", "Required parameter verifySmsCodeDto was null or undefined when calling verifySmsCode.");
            }
            const localVarPath = `/api/accountsadmin/public/sms-code/verify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof verifySmsCodeDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(verifySmsCodeDto !== undefined ? verifySmsCodeDto : {})
                : verifySmsCodeDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verify authenticated user phone by entered sms code
         * @param {VerifySmsCodeDto} verifySmsCodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifySmsCode1(verifySmsCodeDto, options = {}) {
            // verify required parameter 'verifySmsCodeDto' is not null or undefined
            if (verifySmsCodeDto === null || verifySmsCodeDto === undefined) {
                throw new RequiredError("verifySmsCodeDto", "Required parameter verifySmsCodeDto was null or undefined when calling verifySmsCode1.");
            }
            const localVarPath = `/api/accountsadmin/c/sms-code/verify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof verifySmsCodeDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(verifySmsCodeDto !== undefined ? verifySmsCodeDto : {})
                : verifySmsCodeDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * SmsCodeEndpointApi - functional programming interface
 * @export
 */
export const SmsCodeEndpointApiFp = function (configuration) {
    return {
        /**
         * Send sms code for authorization
         * @param {SendSmsCodeDto} sendSmsCodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSmsCodeForAuthorization(sendSmsCodeDto, options) {
            const localVarAxiosArgs = SmsCodeEndpointApiAxiosParamCreator(configuration).sendSmsCodeForAuthorization(sendSmsCodeDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Send sms code for verification
         * @param {SendSmsCodeDto} sendSmsCodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSmsCodeForPhoneVerification(sendSmsCodeDto, options) {
            const localVarAxiosArgs = SmsCodeEndpointApiAxiosParamCreator(configuration).sendSmsCodeForPhoneVerification(sendSmsCodeDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Verify phone by entered sms code
         * @param {VerifySmsCodeDto} verifySmsCodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifySmsCode(verifySmsCodeDto, options) {
            const localVarAxiosArgs = SmsCodeEndpointApiAxiosParamCreator(configuration).verifySmsCode(verifySmsCodeDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Verify authenticated user phone by entered sms code
         * @param {VerifySmsCodeDto} verifySmsCodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifySmsCode1(verifySmsCodeDto, options) {
            const localVarAxiosArgs = SmsCodeEndpointApiAxiosParamCreator(configuration).verifySmsCode1(verifySmsCodeDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * SmsCodeEndpointApi - factory interface
 * @export
 */
export const SmsCodeEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Send sms code for authorization
         * @param {SendSmsCodeDto} sendSmsCodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSmsCodeForAuthorization(sendSmsCodeDto, options) {
            return SmsCodeEndpointApiFp(configuration).sendSmsCodeForAuthorization(sendSmsCodeDto, options)(axios, basePath);
        },
        /**
         * Send sms code for verification
         * @param {SendSmsCodeDto} sendSmsCodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSmsCodeForPhoneVerification(sendSmsCodeDto, options) {
            return SmsCodeEndpointApiFp(configuration).sendSmsCodeForPhoneVerification(sendSmsCodeDto, options)(axios, basePath);
        },
        /**
         * Verify phone by entered sms code
         * @param {VerifySmsCodeDto} verifySmsCodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifySmsCode(verifySmsCodeDto, options) {
            return SmsCodeEndpointApiFp(configuration).verifySmsCode(verifySmsCodeDto, options)(axios, basePath);
        },
        /**
         * Verify authenticated user phone by entered sms code
         * @param {VerifySmsCodeDto} verifySmsCodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifySmsCode1(verifySmsCodeDto, options) {
            return SmsCodeEndpointApiFp(configuration).verifySmsCode1(verifySmsCodeDto, options)(axios, basePath);
        },
    };
};
/**
 * SmsCodeEndpointApi - object-oriented interface
 * @export
 * @class SmsCodeEndpointApi
 * @extends {BaseAPI}
 */
export class SmsCodeEndpointApi extends BaseAPI {
    /**
     * Send sms code for authorization
     * @param {SendSmsCodeDto} sendSmsCodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SmsCodeEndpointApi
     */
    sendSmsCodeForAuthorization(sendSmsCodeDto, options) {
        return SmsCodeEndpointApiFp(this.configuration).sendSmsCodeForAuthorization(sendSmsCodeDto, options)(this.axios, this.basePath);
    }
    /**
     * Send sms code for verification
     * @param {SendSmsCodeDto} sendSmsCodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SmsCodeEndpointApi
     */
    sendSmsCodeForPhoneVerification(sendSmsCodeDto, options) {
        return SmsCodeEndpointApiFp(this.configuration).sendSmsCodeForPhoneVerification(sendSmsCodeDto, options)(this.axios, this.basePath);
    }
    /**
     * Verify phone by entered sms code
     * @param {VerifySmsCodeDto} verifySmsCodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SmsCodeEndpointApi
     */
    verifySmsCode(verifySmsCodeDto, options) {
        return SmsCodeEndpointApiFp(this.configuration).verifySmsCode(verifySmsCodeDto, options)(this.axios, this.basePath);
    }
    /**
     * Verify authenticated user phone by entered sms code
     * @param {VerifySmsCodeDto} verifySmsCodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SmsCodeEndpointApi
     */
    verifySmsCode1(verifySmsCodeDto, options) {
        return SmsCodeEndpointApiFp(this.configuration).verifySmsCode1(verifySmsCodeDto, options)(this.axios, this.basePath);
    }
}
/**
 * TariffLimitsEndpointApi - axios parameter creator
 * @export
 */
export const TariffLimitsEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {DecrementAdditionalPackageDto} decrementAdditionalPackageDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        decrementAdditionalPackage(accountId, decrementAdditionalPackageDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling decrementAdditionalPackage.");
            }
            // verify required parameter 'decrementAdditionalPackageDto' is not null or undefined
            if (decrementAdditionalPackageDto === null ||
                decrementAdditionalPackageDto === undefined) {
                throw new RequiredError("decrementAdditionalPackageDto", "Required parameter decrementAdditionalPackageDto was null or undefined when calling decrementAdditionalPackage.");
            }
            const localVarPath = `/api/accountsadmin/c/accounts/{accountId}/decrement-additional-package`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof decrementAdditionalPackageDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(decrementAdditionalPackageDto !== undefined
                    ? decrementAdditionalPackageDto
                    : {})
                : decrementAdditionalPackageDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentLimits(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getAgentLimits.");
            }
            const localVarPath = `/api/accountsadmin/c/accounts/{accountId}/agent-limits`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * TariffLimitsEndpointApi - functional programming interface
 * @export
 */
export const TariffLimitsEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {DecrementAdditionalPackageDto} decrementAdditionalPackageDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        decrementAdditionalPackage(accountId, decrementAdditionalPackageDto, options) {
            const localVarAxiosArgs = TariffLimitsEndpointApiAxiosParamCreator(configuration).decrementAdditionalPackage(accountId, decrementAdditionalPackageDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentLimits(accountId, options) {
            const localVarAxiosArgs = TariffLimitsEndpointApiAxiosParamCreator(configuration).getAgentLimits(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * TariffLimitsEndpointApi - factory interface
 * @export
 */
export const TariffLimitsEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {DecrementAdditionalPackageDto} decrementAdditionalPackageDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        decrementAdditionalPackage(accountId, decrementAdditionalPackageDto, options) {
            return TariffLimitsEndpointApiFp(configuration).decrementAdditionalPackage(accountId, decrementAdditionalPackageDto, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentLimits(accountId, options) {
            return TariffLimitsEndpointApiFp(configuration).getAgentLimits(accountId, options)(axios, basePath);
        },
    };
};
/**
 * TariffLimitsEndpointApi - object-oriented interface
 * @export
 * @class TariffLimitsEndpointApi
 * @extends {BaseAPI}
 */
export class TariffLimitsEndpointApi extends BaseAPI {
    /**
     *
     * @param {number} accountId
     * @param {DecrementAdditionalPackageDto} decrementAdditionalPackageDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TariffLimitsEndpointApi
     */
    decrementAdditionalPackage(accountId, decrementAdditionalPackageDto, options) {
        return TariffLimitsEndpointApiFp(this.configuration).decrementAdditionalPackage(accountId, decrementAdditionalPackageDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TariffLimitsEndpointApi
     */
    getAgentLimits(accountId, options) {
        return TariffLimitsEndpointApiFp(this.configuration).getAgentLimits(accountId, options)(this.axios, this.basePath);
    }
}
/**
 * UserEndpointApi - axios parameter creator
 * @export
 */
export const UserEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} userId
         * @param {ChangeEmailDto} changeEmailDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmail(userId, changeEmailDto, options = {}) {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError("userId", "Required parameter userId was null or undefined when calling changeEmail.");
            }
            // verify required parameter 'changeEmailDto' is not null or undefined
            if (changeEmailDto === null || changeEmailDto === undefined) {
                throw new RequiredError("changeEmailDto", "Required parameter changeEmailDto was null or undefined when calling changeEmail.");
            }
            const localVarPath = `/api/accountsadmin/c/users/{userId}/email`.replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof changeEmailDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(changeEmailDto !== undefined ? changeEmailDto : {})
                : changeEmailDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {ChangeFullNameDto} changeFullNameDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeFullName(userId, changeFullNameDto, options = {}) {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError("userId", "Required parameter userId was null or undefined when calling changeFullName.");
            }
            // verify required parameter 'changeFullNameDto' is not null or undefined
            if (changeFullNameDto === null || changeFullNameDto === undefined) {
                throw new RequiredError("changeFullNameDto", "Required parameter changeFullNameDto was null or undefined when calling changeFullName.");
            }
            const localVarPath = `/api/accountsadmin/c/users/{userId}/full-name`.replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof changeFullNameDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(changeFullNameDto !== undefined ? changeFullNameDto : {})
                : changeFullNameDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {string} locale
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLanguage(userId, locale, options = {}) {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError("userId", "Required parameter userId was null or undefined when calling changeLanguage.");
            }
            // verify required parameter 'locale' is not null or undefined
            if (locale === null || locale === undefined) {
                throw new RequiredError("locale", "Required parameter locale was null or undefined when calling changeLanguage.");
            }
            const localVarPath = `/api/accountsadmin/c/users/{userId}/language`.replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (locale !== undefined) {
                localVarQueryParameter["locale"] = locale;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {ChangeUserPasswordDto} changeUserPasswordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(userId, changeUserPasswordDto, options = {}) {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError("userId", "Required parameter userId was null or undefined when calling changePassword.");
            }
            // verify required parameter 'changeUserPasswordDto' is not null or undefined
            if (changeUserPasswordDto === null ||
                changeUserPasswordDto === undefined) {
                throw new RequiredError("changeUserPasswordDto", "Required parameter changeUserPasswordDto was null or undefined when calling changePassword.");
            }
            const localVarPath = `/api/accountsadmin/c/users/{userId}/password`.replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof changeUserPasswordDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(changeUserPasswordDto !== undefined ? changeUserPasswordDto : {})
                : changeUserPasswordDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {ChangeTimeZoneDto} changeTimeZoneDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeTimeZone(userId, changeTimeZoneDto, options = {}) {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError("userId", "Required parameter userId was null or undefined when calling changeTimeZone.");
            }
            // verify required parameter 'changeTimeZoneDto' is not null or undefined
            if (changeTimeZoneDto === null || changeTimeZoneDto === undefined) {
                throw new RequiredError("changeTimeZoneDto", "Required parameter changeTimeZoneDto was null or undefined when calling changeTimeZone.");
            }
            const localVarPath = `/api/accountsadmin/c/users/{userId}/time-zone`.replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof changeTimeZoneDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(changeTimeZoneDto !== undefined ? changeTimeZoneDto : {})
                : changeTimeZoneDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAvatar(userId, options = {}) {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError("userId", "Required parameter userId was null or undefined when calling deleteAvatar.");
            }
            const localVarPath = `/api/accountsadmin/c/users/{userId}/avatar`.replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {string} [product]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllowedAccounts(userId, product, options = {}) {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError("userId", "Required parameter userId was null or undefined when calling getAllowedAccounts.");
            }
            const localVarPath = `/api/accountsadmin/c/users/{userId}/allowed-accounts`.replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (product !== undefined) {
                localVarQueryParameter["product"] = product;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllowedModes(userId, options = {}) {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError("userId", "Required parameter userId was null or undefined when calling getAllowedModes.");
            }
            const localVarPath = `/api/accountsadmin/c/users/{userId}/allowed-modes`.replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {string} [product]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToSAndPoS(userId, product, options = {}) {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling getToSAndPoS.');
            }
            const localVarPath = `/api/accountsadmin/c/users/{userId}/tos-and-pos`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (product !== undefined) {
                localVarQueryParameter['product'] = product;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {string} actionType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isCaptchaNeeded(userId, actionType, options = {}) {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError("userId", "Required parameter userId was null or undefined when calling isCaptchaNeeded.");
            }
            // verify required parameter 'actionType' is not null or undefined
            if (actionType === null || actionType === undefined) {
                throw new RequiredError("actionType", "Required parameter actionType was null or undefined when calling isCaptchaNeeded.");
            }
            const localVarPath = `/api/accountsadmin/c/users/{userId}/is-captcha-needed`.replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (actionType !== undefined) {
                localVarQueryParameter["actionType"] = actionType;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAndSetAvatar(userId, file, options = {}) {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError("userId", "Required parameter userId was null or undefined when calling uploadAndSetAvatar.");
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError("file", "Required parameter file was null or undefined when calling uploadAndSetAvatar.");
            }
            const localVarPath = `/api/accountsadmin/c/users/{userId}/upload-and-set-avatar`.replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new FormData();
            if (file !== undefined) {
                localVarFormParams.append("file", file);
            }
            localVarHeaderParameter["Content-Type"] = "multipart/form-data";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * UserEndpointApi - functional programming interface
 * @export
 */
export const UserEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} userId
         * @param {ChangeEmailDto} changeEmailDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmail(userId, changeEmailDto, options) {
            const localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).changeEmail(userId, changeEmailDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {ChangeFullNameDto} changeFullNameDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeFullName(userId, changeFullNameDto, options) {
            const localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).changeFullName(userId, changeFullNameDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {string} locale
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLanguage(userId, locale, options) {
            const localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).changeLanguage(userId, locale, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {ChangeUserPasswordDto} changeUserPasswordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(userId, changeUserPasswordDto, options) {
            const localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).changePassword(userId, changeUserPasswordDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {ChangeTimeZoneDto} changeTimeZoneDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeTimeZone(userId, changeTimeZoneDto, options) {
            const localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).changeTimeZone(userId, changeTimeZoneDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAvatar(userId, options) {
            const localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).deleteAvatar(userId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {string} [product]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllowedAccounts(userId, product, options) {
            const localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).getAllowedAccounts(userId, product, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllowedModes(userId, options) {
            const localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).getAllowedModes(userId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {string} [product]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToSAndPoS(userId, product, options) {
            const localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).getToSAndPoS(userId, product, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {string} actionType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isCaptchaNeeded(userId, actionType, options) {
            const localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).isCaptchaNeeded(userId, actionType, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAndSetAvatar(userId, file, options) {
            const localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).uploadAndSetAvatar(userId, file, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * UserEndpointApi - factory interface
 * @export
 */
export const UserEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} userId
         * @param {ChangeEmailDto} changeEmailDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmail(userId, changeEmailDto, options) {
            return UserEndpointApiFp(configuration).changeEmail(userId, changeEmailDto, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {ChangeFullNameDto} changeFullNameDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeFullName(userId, changeFullNameDto, options) {
            return UserEndpointApiFp(configuration).changeFullName(userId, changeFullNameDto, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {string} locale
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLanguage(userId, locale, options) {
            return UserEndpointApiFp(configuration).changeLanguage(userId, locale, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {ChangeUserPasswordDto} changeUserPasswordDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(userId, changeUserPasswordDto, options) {
            return UserEndpointApiFp(configuration).changePassword(userId, changeUserPasswordDto, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {ChangeTimeZoneDto} changeTimeZoneDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeTimeZone(userId, changeTimeZoneDto, options) {
            return UserEndpointApiFp(configuration).changeTimeZone(userId, changeTimeZoneDto, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAvatar(userId, options) {
            return UserEndpointApiFp(configuration).deleteAvatar(userId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {string} [product]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllowedAccounts(userId, product, options) {
            return UserEndpointApiFp(configuration).getAllowedAccounts(userId, product, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllowedModes(userId, options) {
            return UserEndpointApiFp(configuration).getAllowedModes(userId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {string} [product]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToSAndPoS(userId, product, options) {
            return UserEndpointApiFp(configuration).getToSAndPoS(userId, product, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {string} actionType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isCaptchaNeeded(userId, actionType, options) {
            return UserEndpointApiFp(configuration).isCaptchaNeeded(userId, actionType, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAndSetAvatar(userId, file, options) {
            return UserEndpointApiFp(configuration).uploadAndSetAvatar(userId, file, options)(axios, basePath);
        },
    };
};
/**
 * UserEndpointApi - object-oriented interface
 * @export
 * @class UserEndpointApi
 * @extends {BaseAPI}
 */
export class UserEndpointApi extends BaseAPI {
    /**
     *
     * @param {number} userId
     * @param {ChangeEmailDto} changeEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEndpointApi
     */
    changeEmail(userId, changeEmailDto, options) {
        return UserEndpointApiFp(this.configuration).changeEmail(userId, changeEmailDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} userId
     * @param {ChangeFullNameDto} changeFullNameDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEndpointApi
     */
    changeFullName(userId, changeFullNameDto, options) {
        return UserEndpointApiFp(this.configuration).changeFullName(userId, changeFullNameDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} userId
     * @param {string} locale
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEndpointApi
     */
    changeLanguage(userId, locale, options) {
        return UserEndpointApiFp(this.configuration).changeLanguage(userId, locale, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} userId
     * @param {ChangeUserPasswordDto} changeUserPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEndpointApi
     */
    changePassword(userId, changeUserPasswordDto, options) {
        return UserEndpointApiFp(this.configuration).changePassword(userId, changeUserPasswordDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} userId
     * @param {ChangeTimeZoneDto} changeTimeZoneDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEndpointApi
     */
    changeTimeZone(userId, changeTimeZoneDto, options) {
        return UserEndpointApiFp(this.configuration).changeTimeZone(userId, changeTimeZoneDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEndpointApi
     */
    deleteAvatar(userId, options) {
        return UserEndpointApiFp(this.configuration).deleteAvatar(userId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} userId
     * @param {string} [product]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEndpointApi
     */
    getAllowedAccounts(userId, product, options) {
        return UserEndpointApiFp(this.configuration).getAllowedAccounts(userId, product, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEndpointApi
     */
    getAllowedModes(userId, options) {
        return UserEndpointApiFp(this.configuration).getAllowedModes(userId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} userId
     * @param {string} [product]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEndpointApi
     */
    getToSAndPoS(userId, product, options) {
        return UserEndpointApiFp(this.configuration).getToSAndPoS(userId, product, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} userId
     * @param {string} actionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEndpointApi
     */
    isCaptchaNeeded(userId, actionType, options) {
        return UserEndpointApiFp(this.configuration).isCaptchaNeeded(userId, actionType, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} userId
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEndpointApi
     */
    uploadAndSetAvatar(userId, file, options) {
        return UserEndpointApiFp(this.configuration).uploadAndSetAvatar(userId, file, options)(this.axios, this.basePath);
    }
}
/**
 * UserManagementEndpointApi - axios parameter creator
 * @export
 */
export const UserManagementEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {CreateUserAsSadminDto} createUserAsSadminDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserAsSadmin(createUserAsSadminDto, options = {}) {
            // verify required parameter 'createUserAsSadminDto' is not null or undefined
            if (createUserAsSadminDto === null ||
                createUserAsSadminDto === undefined) {
                throw new RequiredError("createUserAsSadminDto", "Required parameter createUserAsSadminDto was null or undefined when calling createUserAsSadmin.");
            }
            const localVarPath = `/api/accountsadmin/c/sadmin/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof createUserAsSadminDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createUserAsSadminDto !== undefined ? createUserAsSadminDto : {})
                : createUserAsSadminDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [searchText]
         * @param {boolean} [internal]
         * @param {boolean} [enabled]
         * @param {boolean} [fetchRoles]
         * @param {boolean} [fetchAccounts]
         * @param {number} [excludeAccountId]
         * @param {boolean} [excludeAccountByInvitations]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsersByFilterAsSadmin(page, size, searchText, internal, enabled, fetchRoles, fetchAccounts, excludeAccountId, excludeAccountByInvitations, options = {}) {
            const localVarPath = `/api/accountsadmin/c/sadmin/users/search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (page !== undefined) {
                localVarQueryParameter["page"] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter["size"] = size;
            }
            if (searchText !== undefined) {
                localVarQueryParameter["searchText"] = searchText;
            }
            if (internal !== undefined) {
                localVarQueryParameter["internal"] = internal;
            }
            if (enabled !== undefined) {
                localVarQueryParameter['enabled'] = enabled;
            }
            if (fetchRoles !== undefined) {
                localVarQueryParameter["fetchRoles"] = fetchRoles;
            }
            if (fetchAccounts !== undefined) {
                localVarQueryParameter["fetchAccounts"] = fetchAccounts;
            }
            if (excludeAccountId !== undefined) {
                localVarQueryParameter["excludeAccountId"] = excludeAccountId;
            }
            if (excludeAccountByInvitations !== undefined) {
                localVarQueryParameter["excludeAccountByInvitations"] =
                    excludeAccountByInvitations;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAsSadmin(userId, options = {}) {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError("userId", "Required parameter userId was null or undefined when calling getUserAsSadmin.");
            }
            const localVarPath = `/api/accountsadmin/c/sadmin/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} userId
         * @param {UpdateUserAsSadminDto} updateUserAsSadminDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAsSadmin(userId, updateUserAsSadminDto, options = {}) {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError("userId", "Required parameter userId was null or undefined when calling updateUserAsSadmin.");
            }
            // verify required parameter 'updateUserAsSadminDto' is not null or undefined
            if (updateUserAsSadminDto === null ||
                updateUserAsSadminDto === undefined) {
                throw new RequiredError("updateUserAsSadminDto", "Required parameter updateUserAsSadminDto was null or undefined when calling updateUserAsSadmin.");
            }
            const localVarPath = `/api/accountsadmin/c/sadmin/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof updateUserAsSadminDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updateUserAsSadminDto !== undefined ? updateUserAsSadminDto : {})
                : updateUserAsSadminDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * UserManagementEndpointApi - functional programming interface
 * @export
 */
export const UserManagementEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {CreateUserAsSadminDto} createUserAsSadminDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserAsSadmin(createUserAsSadminDto, options) {
            const localVarAxiosArgs = UserManagementEndpointApiAxiosParamCreator(configuration).createUserAsSadmin(createUserAsSadminDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [searchText]
         * @param {boolean} [internal]
         * @param {boolean} [enabled]
         * @param {boolean} [fetchRoles]
         * @param {boolean} [fetchAccounts]
         * @param {number} [excludeAccountId]
         * @param {boolean} [excludeAccountByInvitations]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsersByFilterAsSadmin(page, size, searchText, internal, enabled, fetchRoles, fetchAccounts, excludeAccountId, excludeAccountByInvitations, options) {
            const localVarAxiosArgs = UserManagementEndpointApiAxiosParamCreator(configuration).findUsersByFilterAsSadmin(page, size, searchText, internal, enabled, fetchRoles, fetchAccounts, excludeAccountId, excludeAccountByInvitations, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAsSadmin(userId, options) {
            const localVarAxiosArgs = UserManagementEndpointApiAxiosParamCreator(configuration).getUserAsSadmin(userId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} userId
         * @param {UpdateUserAsSadminDto} updateUserAsSadminDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAsSadmin(userId, updateUserAsSadminDto, options) {
            const localVarAxiosArgs = UserManagementEndpointApiAxiosParamCreator(configuration).updateUserAsSadmin(userId, updateUserAsSadminDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * UserManagementEndpointApi - factory interface
 * @export
 */
export const UserManagementEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {CreateUserAsSadminDto} createUserAsSadminDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserAsSadmin(createUserAsSadminDto, options) {
            return UserManagementEndpointApiFp(configuration).createUserAsSadmin(createUserAsSadminDto, options)(axios, basePath);
        },
        /**
         *
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [searchText]
         * @param {boolean} [internal]
         * @param {boolean} [enabled]
         * @param {boolean} [fetchRoles]
         * @param {boolean} [fetchAccounts]
         * @param {number} [excludeAccountId]
         * @param {boolean} [excludeAccountByInvitations]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsersByFilterAsSadmin(page, size, searchText, internal, enabled, fetchRoles, fetchAccounts, excludeAccountId, excludeAccountByInvitations, options) {
            return UserManagementEndpointApiFp(configuration).findUsersByFilterAsSadmin(page, size, searchText, internal, enabled, fetchRoles, fetchAccounts, excludeAccountId, excludeAccountByInvitations, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAsSadmin(userId, options) {
            return UserManagementEndpointApiFp(configuration).getUserAsSadmin(userId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} userId
         * @param {UpdateUserAsSadminDto} updateUserAsSadminDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAsSadmin(userId, updateUserAsSadminDto, options) {
            return UserManagementEndpointApiFp(configuration).updateUserAsSadmin(userId, updateUserAsSadminDto, options)(axios, basePath);
        },
    };
};
/**
 * UserManagementEndpointApi - object-oriented interface
 * @export
 * @class UserManagementEndpointApi
 * @extends {BaseAPI}
 */
export class UserManagementEndpointApi extends BaseAPI {
    /**
     *
     * @param {CreateUserAsSadminDto} createUserAsSadminDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementEndpointApi
     */
    createUserAsSadmin(createUserAsSadminDto, options) {
        return UserManagementEndpointApiFp(this.configuration).createUserAsSadmin(createUserAsSadminDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [searchText]
     * @param {boolean} [internal]
     * @param {boolean} [enabled]
     * @param {boolean} [fetchRoles]
     * @param {boolean} [fetchAccounts]
     * @param {number} [excludeAccountId]
     * @param {boolean} [excludeAccountByInvitations]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementEndpointApi
     */
    findUsersByFilterAsSadmin(page, size, searchText, internal, enabled, fetchRoles, fetchAccounts, excludeAccountId, excludeAccountByInvitations, options) {
        return UserManagementEndpointApiFp(this.configuration).findUsersByFilterAsSadmin(page, size, searchText, internal, enabled, fetchRoles, fetchAccounts, excludeAccountId, excludeAccountByInvitations, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementEndpointApi
     */
    getUserAsSadmin(userId, options) {
        return UserManagementEndpointApiFp(this.configuration).getUserAsSadmin(userId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} userId
     * @param {UpdateUserAsSadminDto} updateUserAsSadminDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementEndpointApi
     */
    updateUserAsSadmin(userId, updateUserAsSadminDto, options) {
        return UserManagementEndpointApiFp(this.configuration).updateUserAsSadmin(userId, updateUserAsSadminDto, options)(this.axios, this.basePath);
    }
}
/**
 * UserSearchEndpointApi - axios parameter creator
 * @export
 */
export const UserSearchEndpointApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {number} page
         * @param {number} size
         * @param {string} searchText
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAccountUsers(accountId, page, size, searchText, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling findAccountUsers.");
            }
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError("page", "Required parameter page was null or undefined when calling findAccountUsers.");
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError("size", "Required parameter size was null or undefined when calling findAccountUsers.");
            }
            // verify required parameter 'searchText' is not null or undefined
            if (searchText === null || searchText === undefined) {
                throw new RequiredError("searchText", "Required parameter searchText was null or undefined when calling findAccountUsers.");
            }
            const localVarPath = `/api/accountsadmin/c/accounts/{accountId}/users`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (page !== undefined) {
                localVarQueryParameter["page"] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter["size"] = size;
            }
            if (searchText !== undefined) {
                localVarQueryParameter["searchText"] = searchText;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * UserSearchEndpointApi - functional programming interface
 * @export
 */
export const UserSearchEndpointApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {number} page
         * @param {number} size
         * @param {string} searchText
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAccountUsers(accountId, page, size, searchText, options) {
            const localVarAxiosArgs = UserSearchEndpointApiAxiosParamCreator(configuration).findAccountUsers(accountId, page, size, searchText, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * UserSearchEndpointApi - factory interface
 * @export
 */
export const UserSearchEndpointApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} accountId
         * @param {number} page
         * @param {number} size
         * @param {string} searchText
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAccountUsers(accountId, page, size, searchText, options) {
            return UserSearchEndpointApiFp(configuration).findAccountUsers(accountId, page, size, searchText, options)(axios, basePath);
        },
    };
};
/**
 * UserSearchEndpointApi - object-oriented interface
 * @export
 * @class UserSearchEndpointApi
 * @extends {BaseAPI}
 */
export class UserSearchEndpointApi extends BaseAPI {
    /**
     *
     * @param {number} accountId
     * @param {number} page
     * @param {number} size
     * @param {string} searchText
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSearchEndpointApi
     */
    findAccountUsers(accountId, page, size, searchText, options) {
        return UserSearchEndpointApiFp(this.configuration).findAccountUsers(accountId, page, size, searchText, options)(this.axios, this.basePath);
    }
}
